<template>
  <div>
    <a-modal
      title="Shift Actions List"
      v-model="visibleLocationsModal"
      width="850px"
      :footer="null"
      @cancel="closeLocationsModal"
      :destroy-on-close="true"
    >
      <locations :list="currentLocationsList" />
    </a-modal>

    <a-modal
      title="Decline Shift Payments"
      v-model="visibleDeclineModal"
      width="350px"
      @cancel="closeDeclineModal"
      :destroy-on-close="true"
    >
      <a-textarea v-model="cancelReason" placeholder="Enter Decline Reason" />
      <template slot="footer">
        <div class="flex justify-content-between">
          <a-button type="danger" @click="closeDeclineModal"> Cancel </a-button>
          <a-button
            type="primary"
            :disabled="!this.cancelReason.trim()"
            @click="declineShifts"
          >
            Decline
          </a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      width="800px"
      :title="title"
      v-model="visible"
      :centered="true"
      :destroy-on-close="true"
      @ok="closeModal"
      @cancel="closeModal"
    >
      <div v-if="loadingPayment || !paymentInfo.payment" class="loading-spin">
        <a-spin />
      </div>

      <template v-else>
        <div class="flex flex-column gap-4">
          <payment-balance
            v-if="availableSystem"
            :payment_system_id="availableSystem"
          />

          <div class="flex gap-4">
            <div class="w-6">
              <h3>Info</h3>

              <div class="grid">
                <b class="col-4">{{ paymentAuthor }}</b>
                <span class="col-8">
                  <grouped-associate-info
                    :first-name="paymentInfo.associate.first_name"
                    :last-name="paymentInfo.associate.last_name"
                    :photo-url="paymentInfo.associate.photo_url"
                    :associate-number="paymentInfo.associate.associate_number"
                /></span>

                <template
                  v-if="paymentInfo.payment && paymentInfo.payment.reviewer"
                >
                  <b class="col-4">Reviewer</b>
                  <span class="col-8">
                    <grouped-associate-info
                      :first-name="paymentInfo.payment.reviewer.first_name"
                      :last-name="paymentInfo.payment.reviewer.last_name"
                      :photo-url="paymentInfo.payment.reviewer.photo_url"
                      :associate-number="
                        paymentInfo.payment.reviewer.associate_number
                      "
                    />
                  </span>
                </template>

                <template v-if="this.paymentInfo.payment.type !== 'employee'">
                  <b class="col-4">Legal Entity</b>
                  <span class="col-8">{{
                    paymentInfo.associate.legal_entity.name
                  }}</span>
                  <b class="col-4">Tax Id</b>
                  <span class="col-8">{{
                    paymentInfo.associate.legal_entity?.tax_id ?? " - "
                  }}</span>
                </template>

                <b class="col-4">ID</b>
                <span class="col-8">{{ paymentInfo.payment.id }}</span>

                <b class="col-4">System</b>
                <span class="col-8">{{ combinedPaymentSystem }}</span>

                <b class="col-4">Status</b>
                <span class="col-8">
                  <a-tag
                    class="status-tag"
                    :color="
                      getPaymentStatus(
                        paymentInfo.payment.status,
                        paymentInfo.payment.method
                      ).color
                    "
                  >
                    {{
                      getPaymentStatus(
                        paymentInfo.payment.status,
                        paymentInfo.payment.method
                      ).title
                    }}
                  </a-tag>
                </span>

                <b class="col-4">Total, $</b>
                <span class="col-8">{{ convertedTotal }}</span>

                <b class="col-4">Net Amount, $</b>
                <span class="col-8">{{ convertedNetTotal }}</span>
              </div>
            </div>
            <div class="w-6">
              <h3>Content</h3>

              <div class="grid">
                <template v-if="availableAgreement"
                  ><b class="col-8">Agreement</b>
                  <div class="col-4">
                    <a-dropdown size="small">
                      <a-button
                        @click="downloadAssociateAgreement"
                        :loading="loadingAgreement"
                        size="small"
                        icon="download"
                      >
                        Download
                      </a-button>
                    </a-dropdown>
                  </div></template
                >

                <b class="col-8">Invoice</b>
                <div class="col-4">
                  <a-dropdown size="small" :disabled="!canDownloadInvoice">
                    <a-button
                      @click="openAttachmentOnNewPage(paymentInfo.invoice.link)"
                      :disabled="loading"
                      size="small"
                      icon="download"
                    >
                      Download
                    </a-button>
                  </a-dropdown>
                </div>

                <b class="col-8">Time/Attendance Detail Report</b>
                <div class="col-4">
                  <a-dropdown :disabled="contentDisabled" size="small">
                    <a-menu slot="overlay">
                      <a-menu-item
                        key="pdf"
                        @click="
                          downloadTimeAttendanceReport(
                            timeAttendanceExportFormatType.PDF
                          )
                        "
                      >
                        <a-icon type="file-pdf" />
                        PDF
                      </a-menu-item>
                      <a-menu-item
                        key="excel"
                        @click="
                          downloadTimeAttendanceReport(
                            timeAttendanceExportFormatType.EXCEL
                          )
                        "
                      >
                        <a-icon type="file-excel" />
                        Excel
                      </a-menu-item>
                    </a-menu>
                    <a-button :disabled="loading" size="small" icon="download">
                      Download
                    </a-button>
                  </a-dropdown>
                </div>

                <b class="col-8">Actions</b>
                <div class="col-4">
                  <a-button
                    size="small"
                    icon="environment"
                    :disabled="locationsLoading || contentDisabled"
                    @click="showLocationsInfo"
                  >
                    Open
                  </a-button>
                </div>

                <template
                  v-if="
                    paymentInfo.attached_file_urls &&
                    paymentInfo.attached_file_urls.length > 0
                  "
                >
                  <b class="col-6">Files</b>
                  <div class="col-6">
                    <a-button size="small" icon="download" @click="openFiles">
                      Open
                    </a-button>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <info-shifts
            :selected-row-keys.sync="selectedShifts"
            :available-validation="availableShiftValidation"
          />
        </div>

        <template slot="footer" v-if="availableShiftValidation">
          <div class="flex justify-content-end">
            <a-button
              :disabled="!selectedShifts.length"
              type="danger"
              icon="close"
              @click="openDeclineModal"
            >
              Decline {{ selectedShifts.length ?? " " }} Shifts
            </a-button>
            <a-button
              :disabled="!selectedShifts.length"
              type="primary"
              icon="check"
              @click="approveShifts()"
            >
              Approve {{ selectedShifts.length ?? " " }} Shifts
            </a-button>
          </div>
        </template>

        <template slot="footer" v-if="availablePaymentValidation">
          <div class="flex justify-content-end">
            <a-button type="primary" icon="check" @click="confirmPayment()">
              Approve Payment
            </a-button>
          </div>
        </template>
      </template>
    </a-modal>
  </div>
</template>
<script>
import {
  Button,
  Dropdown,
  Icon,
  Menu,
  notification,
  Tag,
  Spin,
  Input,
  InputNumber,
  Modal,
} from "ant-design-vue";
import api from "@/api";
import Util from "@/util";
import locations from "@/components/locations.vue";
import { createNamespacedHelpers } from "vuex";
import PaymentInfoShifts from "./payment-info-shifts.vue";
import GroupedAssociateInfo from "@/components/grouped-associate-info.vue";
import PaymentSystemBalance from "./payment-system-balance.vue";

const {
  mapState: paymentState,
  mapActions: paymentActions,
  mapGetters: paymentGetters,
} = createNamespacedHelpers("payment");

export default {
  data() {
    return {
      currentLocationsList: undefined,
      cancelReason: "",

      loading: false,
      loadingAgreement: false,
      loadingPayment: false,

      timeAttendanceExportFormatType: {
        PDF: 0,
        EXCEL: 1,
      },

      locationsLoading: false,

      visibleDeclineModal: false,
      visible: false,

      selectedShifts: [],
    };
  },
  props: {
    paymentId: {
      type: Number,
    },
    change: {
      type: Function,
    },
  },
  mixins: [api],
  components: {
    locations,
    "a-button": Button,
    "a-dropdown": Dropdown,
    "a-tag": Tag,
    "a-icon": Icon,
    "a-menu": Menu,
    "a-spin": Spin,
    "a-menu-item": Menu.Item,
    "a-input": Input,
    "a-textarea": Input.TextArea,
    "a-input-number": InputNumber,
    "a-modal": Modal,
    "info-shifts": PaymentInfoShifts,
    "grouped-associate-info": GroupedAssociateInfo,
    "payment-balance": PaymentSystemBalance,
  },
  watch: {
    paymentId(curr, prev) {
      if (prev && !curr) {
        this.visible = false;
      } else {
        this.visible = true;
        this.fetchPaymentInfo();
      }
    },
  },
  computed: {
    ...paymentState({
      paymentInfo: (state) => state.payment,
    }),
    ...paymentGetters([
      "getPaymentStatus",
      "getStripePaymentSystem",
      "getPaymentSystem",
    ]),

    availableSystem() {
      const system = this.getStripePaymentSystem(
        this.paymentInfo.payment.method_id
      );

      return system ? system?.id : undefined;
    },

    title() {
      return this.paymentInfo.payment &&
        this.paymentInfo.payment.type === "employee"
        ? "Loan Request"
        : "Payment Request";
    },

    paymentAuthor() {
      return this.paymentInfo.payment &&
        this.paymentInfo.payment.type === "employee"
        ? "Author"
        : "Legal Entity Admin";
    },

    combinedPaymentSystem() {
      let method = "-";

      if (this.paymentInfo.payment && this.paymentInfo.payment.method) {
        method = Util.capitalize(this.paymentInfo.payment.method);
      }
      if (
        this.paymentInfo.payment.method &&
        this.paymentInfo.payment.manual_payment_method
      ) {
        method +=
          " " +
          "(" +
          Util.splitAndCapitalize(
            this.paymentInfo.payment.manual_payment_method
          ) +
          ")";
      }

      return method;
    },

    convertedTotal() {
      if (!this.paymentInfo.payment) {
        return 0;
      }

      return Util.convertDbToMoney(this.paymentInfo.payment.total);
    },

    convertedNetTotal() {
      if (!this.paymentInfo) {
        return 0;
      }

      return Util.convertDbToMoney(this.paymentInfo.payment.net_amount);
    },

    canDownloadInvoice() {
      return this.paymentInfo.invoice ? this.paymentInfo.invoice.link : false;
    },

    contentDisabled() {
      return (
        !this.paymentInfo.shift_payments ||
        this.paymentInfo.shift_payments.length == 0
      );
    },

    visibleLocationsModal() {
      return this.currentLocationsList;
    },

    availableShiftValidation() {
      return (
        this.paymentInfo.payment.next_action === "wait_for_shifts_approval" &&
        this.$store.state.applicationState.associate.permissions.includes(
          "validate_payments"
        )
      );
    },

    availablePaymentValidation() {
      return (
        this.paymentInfo.payment.next_action === "wait_for_payment_approval" &&
        this.$store.state.applicationState.associate.permissions.includes(
          "process_payments"
        )
      );
    },

    availableAgreement() {
      return !["CREATED", "PROCESSING", "CANCELLED", "CONFIRMED"].includes(
        this.paymentInfo.payment.status
      );
    },
  },
  methods: {
    ...paymentActions([
      "getPayment",
      "approvePaymentRequest",
      "approveShiftPayments",
      "declineShiftPayments",
      "downloadAgreement",
    ]),

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    openAttachmentOnNewPage(file) {
      window.open(file, "_blank");
    },

    // modal
    closeLocationsModal() {
      this.currentLocationsList = undefined;
    },

    openDeclineModal() {
      this.visibleDeclineModal = true;
    },

    closeDeclineModal() {
      this.visibleDeclineModal = false;
    },

    closeModal() {
      this.visible = false;
      this.selectedShifts = [];
      this.$emit("update:paymentId", undefined);
    },

    openFiles() {
      this.paymentInfo.attached_file_urls.forEach((file) => {
        window.open(file.url, "_blank");
      });
    },

    confirmPayment() {
      const system = this.getPaymentSystem(this.paymentInfo.payment.method_id);

      if (system.template_id === 4) {
        Modal.confirm({
          title: "Confirm Ready to Pay",
          content:
            "Please ensure you are fully prepared to issue the payment check to the individual. Once the payment is confirmed, they will approach you to receive it.",
          onOk: () => {
            this.approvePayment();
          },
          onCancel() {},
        });
      } else {
        this.approvePayment();
      }
    },

    async approvePayment() {
      this.loadingPayment = true;

      try {
        const payload = {
          request_id: this.paymentId,
        };

        await this.approvePaymentRequest(payload);
        await this.fetchPaymentInfo();
        this.$emit("change", {}, true);
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingPayment = false;
      }
    },

    async approveShifts() {
      this.loadingPayment = true;
      try {
        const payload = {
          request_id: this.paymentId,
          shift_payment_ids: this.selectedShifts,
        };

        await this.approveShiftPayments(payload);
        await this.fetchPaymentInfo();
        this.$emit("change", {}, true);
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingPayment = false;
      }
    },

    async declineShifts() {
      this.loadingPayment = true;

      try {
        const payload = {
          request_id: this.paymentId,
          reason: this.cancelReason,
          shift_payment_ids: this.selectedShifts,
        };
        this.closeDeclineModal();

        await this.declineShiftPayments(payload);
        await this.fetchPaymentInfo();

        this.$emit("change", {}, true);
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingPayment = false;
      }
    },

    async showLocationsInfo() {
      this.locationsLoading = true;

      try {
        const { body } = await this.apiGetShiftLocations(
          this.paymentInfo.shift_payments.map((shift) => shift.shift.id)
        );

        if (!body.error_code || body.error_code === "0") {
          const locations = body.shift_actions;

          if (
            locations.length === 0 ||
            !locations[0] ||
            !locations[0].records ||
            !locations[0].records.length
          ) {
            this.showLocationsListEmptyMessage();
          } else {
            this.currentLocationsList = locations[0].records;
          }
        } else {
          this.showNotification(
            "error",
            "Error",
            "An error occurred while loading the list of locations. Please try again later"
          );
        }
      } catch (error) {
        this.showNotification(
          "error",
          "Error",
          "An error occurred while loading the list of locations. Please try again later"
        );
      } finally {
        this.locationsLoading = false;
      }
    },

    async downloadTimeAttendanceReport(format) {
      this.loading = true;
      try {
        let resp;
        switch (format) {
          case this.timeAttendanceExportFormatType.PDF:
            resp = await this.apiGetTimeAttendanceDetailReportByShiftIds(
              this.paymentInfo.shift_payments.map((shift) => shift.shift.id)
            );
            break;
          case this.timeAttendanceExportFormatType.EXCEL:
            resp = await this.apiSendShiftsReportByShiftIds(
              this.paymentInfo.shift_payments.map((shift) => shift.shift.id)
            );
            break;
        }

        if (resp.body.error_code && resp.body.error_code !== "0") {
          this.showNotification("warning", "Not Found", resp.body.msg);
        } else {
          window.open(resp.body.report_url, "_blank");
        }
      } catch (error) {
        this.showNotification(
          "warning",
          "Not Found",
          "An error occurred while downloading the report."
        );
      } finally {
        this.loading = false;
      }
    },

    async downloadAssociateAgreement() {
      if (!this.paymentId) {
        return;
      }

      this.loadingAgreement = true;

      const payload = {
        payment_id: this.paymentId,
      };

      try {
        const url = await this.downloadAgreement(payload);

        window.open(url, "_blank");
      } catch (error) {
        this.showNotification("error", "Error", error.message || error);
      } finally {
        this.loadingAgreement = false;
      }
    },

    async fetchPaymentInfo() {
      if (!this.paymentId) {
        return;
      }

      this.loadingPayment = true;
      try {
        await this.getPayment({
          payment_id: this.paymentId,
        });
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingPayment = false;
      }
    },
  },
  mounted() {
    if (this.paymentId) {
      this.visible = true;
      this.fetchPaymentInfo();
    }
  },
};
</script>

<style scoped>
.shifts-to-pay-table {
  height: 300px !important;
  overflow-y: auto;
}

.status-tag {
  margin: unset;
}

.loading-spin {
  background-color: rgba(255, 255, 255);
}
</style>
