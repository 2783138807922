import apiClient from "@/services/api";

export default {
  async getAssociate({ commit, rootState }, { associate_number }) {
    try {
      const { data } = await apiClient.post("/api/associate/get", {
        project_id: rootState.applicationState.currentProject.id,
        associate_number,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          `${
            data.msg ?? "An error occurred while loading the associate."
          } Please try again later`
        );
      }

      commit("setAssociate", data.associate);
    } catch (error) {
      throw error.message;
    }
  },

  async getAssociateById({}, { associate_id }) {
    try {
      const { data } = await apiClient.get(`/api/associate/${associate_id}`);

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          `${
            data.msg ?? "An error occurred while loading the associate."
          } Please try again later`
        );
      }

      return data;
    } catch (error) {
      throw error.message;
    }
  },

  async getKPI({ commit, rootState }, { associate_number, start, end }) {
    try {
      const { data } = await apiClient.post("/api/associate/kpi/get", {
        project_id: rootState.applicationState.currentProject.id,
        associate_number,
        start,
        end,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          `${
            data.msg ?? "An error occurred while loading the KPI."
          } Please try again later`
        );
      }

      commit("setAssociateKPI", data.kpi);
    } catch (error) {
      throw error.message;
    }
  },

  async patchAssociateActivate({}, { associate_id }) {
    try {
      const { data } = await apiClient.patch(
        `/api/associate/${associate_id}/activate`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },

  async patchAssociateDeactivate({}, { associate_id }) {
    try {
      const { data } = await apiClient.patch(
        `/api/associate/${associate_id}/deactivate`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },

  async postAssociateTerminate(
    { rootState },
    { associate_id, termination, user_id }
  ) {
    try {
      const { data } = await apiClient.post(`/api/associate/terminate`, {
        project_id: rootState.applicationState.currentProject.id,
        associate_id,
        user_id,
        termination: {
          ...termination,
          author: rootState.applicationState.associate.id,
        },
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },

  async postAssociateCancelTerminate({}, { associate_id }) {
    try {
      const { data } = await apiClient.post(
        `/api/associate/${associate_id}/termination/cancel`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },

  async postAssociateWriteUp(
    { rootState },
    { writeup, associate_number, signature_id }
  ) {
    try {
      const { data } = await apiClient.post(`/api/associate/writeup/create`, {
        project_id: rootState.applicationState.currentProject.id,
        signature_id,
        writeup: {
          ...writeup,
          author: rootState.applicationState.associate.id,
          consumer: {
            project_id: rootState.applicationState.currentProject.id,
            associate_number: associate_number,
          },
          status: "not_signed",
          authorization: null,
        },
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },

  async createAssociate(
    { rootState },
    { user_id, associate_info, payment_info }
  ) {
    try {
      const { data } = await apiClient.post(`/api/associate/user`, {
        user_id,
        associate_info: {
          ...associate_info,
          project_id: rootState.applicationState.currentProject.id,
        },
        payment_info,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      return data;
    } catch (error) {
      throw error.message;
    }
  },

  async createUser(
    { rootState },
    { user_info, associate_info, payment_info, creation_mode }
  ) {
    try {
      const { data } = await apiClient.post(`/api/associate/create`, {
        user_info,
        associate_info: {
          ...associate_info,
          project_id: rootState.applicationState.currentProject.id,
        },
        payment_info,
        creation_mode,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      return data;
    } catch (error) {
      throw error.message;
    }
  },

  async checkExistingUser({}, { email }) {
    try {
      const { data } = await apiClient.get(`/api/user/email/${email}`);

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      return data;
    } catch (error) {
      throw error.message;
    }
  },

  async checkAvailableAssociateNumber({ rootState }, { associate_number }) {
    try {
      const { data } = await apiClient.post(
        `/api/is_associate_number_available`,
        {
          project_id: rootState.applicationState.currentProject.id,
          associate_number,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      return data.result;
    } catch (error) {
      throw error.message;
    }
  },

  async getCreationContext({}, { associate_id }) {
    try {
      const { data } = await apiClient.get(
        `/api/associate/${associate_id}/create_context`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      return data;
    } catch (error) {
      throw error.message;
    }
  },

  async postCloneAssociate(
    { rootState },
    {
      new_associate_number,
      associate,
      disable_previous_associate,
      is_legal_entity_admin,
    }
  ) {
    try {
      const { data } = await apiClient.post(`/api/associate/clone`, {
        project_id: rootState.applicationState.currentProject.id,
        new_associate_number,
        associate,
        disable_previous_associate,
        is_legal_entity_admin,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      return data;
    } catch (error) {
      throw error.message;
    }
  },

  async downloadExportedAssociate({}, { associate_id }) {
    try {
      const { data } = await apiClient.get(
        `/api/report/associate/${associate_id}/associate_card/download`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      return data.url;
    } catch (error) {
      throw error.message;
    }
  },

  async sendExportedAssociate({}, { associate_id, email }) {
    try {
      const { data } = await apiClient.get(
        `/api/report/associate/${associate_id}/associate_card/send_email/${email}`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },
};
