<template>
  <div>
    <h2 class="view-header">
      <span class="flex gap-2 align-items-center justify-content-center">
        Shift Authorizations
        <a-tooltip placement="right">
          <template slot="title"> Start Tour </template>
          <a-icon
            @click.stop="startTour()"
            class="flex align-items-center cursor-pointer"
            type="question-circle"
            theme="twoTone"
          />
        </a-tooltip>
      </span>
    </h2>

    <project-shift-auths-filters
      :current-filters="filter"
      :visible.sync="filtersModalVisible"
    />

    <history-write-up
      :visible="showHistoryWriteup"
      :associate-number="editingAssociateNumber"
      @close="closeWriteupHistory"
    />

    <v-tour name="authsTour" :steps="tourSteps" :options="vueTourOptions" />

    <send-notification
      :associate="associateProfileToSendSms"
      @close="closeSmsModal"
    />

    <shift-view
      :visible.sync="visibleShiftModal"
      :shift_id="editingAssociateShiftId"
      @edit="fetchAuths"
    />

    <a-modal
      width="1200px"
      v-model="authorizationsListVisible"
      @cancel="handleAuthListCancel"
    >
      <shift-auth-list-modal
        v-if="currentShiftRecord"
        ref="shiftAuthListModal"
        :auths-list="currentShiftAuthsList"
        :associate-first-name="currentShiftRecord.associate?.first_name"
        :associate-last-name="currentShiftRecord.associate?.last_name"
        @change="handleSelectedAuthListChange"
      />
      <template slot="footer">
        <div class="flex justify-content-between">
          <a-button @click="handleAuthListCancel"> Cancel </a-button>
          <div class="flex justify-content-end">
            <a-button
              id="deny-auths-btn"
              type="danger"
              :disabled="selectedCurrentShiftAuthsList.length == 0"
              @click="initDenySelectedAuthList"
            >
              Deny
            </a-button>
            <a-button
              id="accept-auths-btn"
              type="primary"
              :disabled="selectedCurrentShiftAuthsList.length == 0"
              @click="initAcceptSelectedAuthList"
            >
              Accept
            </a-button>
          </div>
        </div>
      </template>
    </a-modal>

    <a-modal
      width="600px"
      title="Reason of Deny Authorization"
      v-model="denyReasonVisible"
      @ok="confirmDeny"
    >
      <shift-auth-deny-form
        ref="shiftAuthDenyForm"
        :decline-reasons="filteredDeclineReasons"
        @reason="setResolveComment"
        @params="setDenyParams"
      />
    </a-modal>
    <a-modal
      width="600px"
      title="Accept Authorization Comment"
      v-model="acceptCommentVisible"
      @ok="confirmAccept"
    >
      <a-input placeholder="Optional" v-model="resolveComment" />
    </a-modal>

    <a-modal
      title="Associate Profile Edit"
      width="80%"
      :footer="null"
      v-model="associateProfileModalVisible"
      @ok="endEditAssociateProfile"
      @cancel="endEditAssociateProfile"
    >
      <div style="overflow-x: auto; width: 100%">
        <associate-profile
          :associate-number.sync="editingAssociateNumber"
          @edited="handleEditedAssociateProfile"
          @clone="openCloneAssociate"
          @close="endEditAssociateProfile"
        />
      </div>
    </a-modal>
    <a-modal
      v-model="cloneAssociateVisible"
      title="Create New Profile"
      width="1000px"
      :footer="null"
    >
      <clone-associate
        :associate-number="cloningAssociateNumber"
        @close="endCloneAssociate"
      />
    </a-modal>

    <div class="flex justify-content-end align-items-center gap-2">
      <div class="flex gap-2">
        <a-badge :count="countFilters">
          <a-button icon="filter" @click="openFilters"> Filters </a-button>
        </a-badge>

        <a-button
          icon="undo"
          :disabled="countFilters === 0"
          @click="resetFilters"
        >
          Reset Filters
        </a-button>
      </div>

      <div class="flex flex-column">
        <div>Total Auths: {{ total_auths || "..." }}</div>
        <div>Shifts With Auths: {{ total_shifts_with_auths || "..." }}</div>
      </div>
    </div>
    <a-table
      size="small"
      :rowKey="(record) => record.id"
      :columns="columns"
      :data-source="tableData"
      :loading="loading"
      :pagination="pagination"
      :expanded-row-render="expandedRowRender"
      :expand-row-by-click="true"
      :scroll="{ x: 1800, y: tableScrollHeight }"
      @change="handleTableChange"
    >
      <span
        slot="authorizations"
        slot-scope="text, record"
        class="flex flex-column align-items-center"
      >
        <a-tooltip
          class="w-fit"
          v-for="auth in record.authorizations"
          :key="auth.id"
        >
          <template slot="title">
            {{
              authTypeDescriptions[auth.type] ?? "Description is not available"
            }}
          </template>
          <a-tag class="auth-tag" :color="getAuthStatusTagColor(auth)">
            {{ convertType(auth.type) }}
          </a-tag>
        </a-tooltip>
      </span>
      <span
        slot="history"
        slot-scope="text, record"
        class="flex flex-column gap-1"
      >
        <span v-for="(item, index) in record.authorizations" :key="index">
          <history-tags
            :is-many-auth="record.authorizations.length > 1"
            :history="item.history"
          />
        </span>
      </span>
      <span slot="date" slot-scope="text, record">
        {{ formatDateString(record.shift.date) }}
      </span>
      <span slot="associate" slot-scope="text, record" :key="record.photo_url">
        <grouped-associate-info
          :first-name="record.associate.first_name"
          :last-name="record.associate.last_name"
          :photo-url="record.associate.photo_url"
          :associate-number="record.associate.associate_number"
        />
      </span>
      <span
        slot="providers"
        slot-scope="text, record"
        style="white-space: pre-wrap"
      >
        <template v-if="!getAuthProviders(record).length">
          No Providers
        </template>
        <template v-else>
          <div v-for="name in getAuthProviders(record)" :key="{ name }">
            {{ name }}
          </div>
        </template>
      </span>
      <span slot="confirmation" slot-scope="text, record">
        <template v-if="!getConfirmationStatus(record)"> - </template>
        <template v-else>
          <a-tag
            class="auth-tag"
            :color="getConfirmationStatusColor(getConfirmationStatus(record))"
          >
            {{ convertConfirmationStatus(getConfirmationStatus(record)) }}
          </a-tag>
        </template>
      </span>
      <span slot="actions" slot-scope="text, record">
        <a-button-group size="small">
          <a-tooltip>
            <template slot="title"> Show Authorizations </template>
            <a-button
              id="show-auths-btn"
              icon="edit"
              @click.stop="showShiftAuthorizations(record)"
            />
          </a-tooltip>

          <a-tooltip>
            <template slot="title"> Open Shift </template>
            <a-button
              id="edit-shift-btn"
              icon="eye"
              @click.stop="openShift(record.shift.id)"
            />
          </a-tooltip>

          <a-tooltip>
            <template slot="title"> Open Profile </template>
            <a-button
              id="edit-profile-btn"
              icon="user"
              @click.stop="
                startEditAssociateProfile(record.associate.associate_number)
              "
            />
          </a-tooltip>

          <a-tooltip>
            <template slot="title"> Open Write-Up History </template>
            <a-button
              id="history-write-up-btn"
              icon="read"
              @click.stop="
                openWriteupHistory(record.associate.associate_number)
              "
            />
          </a-tooltip>

          <a-tooltip>
            <template slot="title"> Send Message </template>
            <a-button
              icon="message"
              id="send-msg-btn"
              :disabled="!record.associate.phone"
              @click.stop="openSmsSendModal(record.associate)"
            />
          </a-tooltip>
        </a-button-group>
      </span>
    </a-table>
  </div>
</template>

<script>
import moment from "moment-timezone";
import ShiftAuthDenyForm from "@/modules/shifts/components/shift-auth-deny.vue";
import ShiftAuthListModal from "@/modules/shifts/components/shift-auth-list.vue";
import SendNotification from "@/components/send-notification.vue";
import {
  Badge,
  Button,
  Icon,
  Input,
  Modal,
  notification,
  Table,
  Tag,
  Tooltip,
} from "ant-design-vue";
import api from "@/api";
import shiftsHelper from "@/helpers/shifts";
import store from "@/store";
import Util from "@/util";
import shiftAuths from "@/helpers/shift-auths";
import AssociateProfile from "@/components/associate-profile.vue";
import GroupedAssociateInfo from "@/components/grouped-associate-info.vue";
import HistoryTags from "@/components/Auth/auth-history-tags.vue";
import ProjectShiftAuthsFilters from "@/modules/shifts/components/shift-auth-filters.vue";
import HistoryWriteUp from "@/components/write-up/write-up-history-modal.vue";
import { shiftAuthDescriptions, statusTagColors } from "@/const";
import CloneAssociate from "@/modules/profile/components/profile-associate-clone.vue";
import ShiftViewModal from "../components/shift-view-modal.vue";
import tour from "../mixins/ShiftsAuthTourMixin";
import table from "../mixins/ShiftsAuthTableMixin";
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers("shifts");

const AuthStatus = Object.freeze({
  INITIAL: "initial",
  ACCEPTED: "accepted",
  DECLINED: "declined",
});

export default {
  components: {
    SendNotification,
    "a-input": Input,
    "a-button": Button,
    "a-modal": Modal,
    "a-button-group": Button.Group,
    "a-table": Table,
    "a-tag": Tag,
    "a-tooltip": Tooltip,
    "a-icon": Icon,
    "a-badge": Badge,
    "shift-auth-deny-form": ShiftAuthDenyForm,
    "shift-auth-list-modal": ShiftAuthListModal,
    "associate-profile": AssociateProfile,
    "grouped-associate-info": GroupedAssociateInfo,
    "project-shift-auths-filters": ProjectShiftAuthsFilters,
    "history-tags": HistoryTags,
    "history-write-up": HistoryWriteUp,
    "clone-associate": CloneAssociate,
    "shift-view": ShiftViewModal,
  },
  props: {
    filter: {
      type: Object,
    },
  },
  data() {
    return {
      tableData: [],

      workDateFormat: store.state.applicationState.dateFormat,
      projectId: store.state.applicationState.currentProject.id,

      loading: false,
      authorizationsListVisible: false,

      currentShiftRecord: null,
      currentShiftAuthsList: [],
      selectedCurrentShiftAuthsList: [],

      showOnlyWithScheduleError: false,
      showOnlyAssignedToUser: false,

      resolveComment: undefined,

      acceptCommentVisible: false,
      denyReasonVisible: false,

      editingAssociateShiftId: undefined,
      visibleAssociateShiftModal: false,

      editingAssociateNumber: undefined,

      associateProfileModalVisible: false,

      cloneAssociateVisible: false,
      cloningAssociateNumber: undefined,

      total_auths: 0,
      total_shifts_with_auths: 0,

      associateProfileToSendSms: undefined,

      showHistoryWriteup: false,
      visibleShiftModal: false,

      params: {
        readyForPayment: false,
        withWriteUp: false,
        suspend: 0,
        terminated: false,
      },

      filtersModalVisible: false,
    };
  },
  mixins: [api, shiftsHelper, tour, table],
  watch: {
    filter: {
      handler(newVal, prevVal) {
        if (JSON.stringify(newVal) != JSON.stringify(prevVal)) {
          this.pagination.current = 1;
          this.fetchAuths();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    user() {
      return this.$store.state.applicationState.user;
    },

    fullSelectedAuths() {
      if (
        this.currentShiftAuthsList.length == 0 ||
        this.currentShiftAuthsList.length == 0
      ) {
        return [];
      }

      return this.currentShiftAuthsList.filter((auth) =>
        this.selectedCurrentShiftAuthsList.includes(auth.id)
      );
    },

    dateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },

    timeFormat() {
      return this.$store.state.applicationState.timeFormat;
    },

    filteredDeclineReasons() {
      const reasons = {};

      if (this.fullSelectedAuths.length > 0) {
        this.fullSelectedAuths.forEach(async (auth) => {
          const declineReasons = await this.getDeclineReasons();
          const authTypeReason = declineReasons[auth.type];
          if (!authTypeReason) {
            return;
          }

          reasons[auth.type] = authTypeReason;
        });
      }

      return reasons;
    },

    authTypeDescriptions() {
      return shiftAuthDescriptions;
    },

    countFilters() {
      return Object.values(this.filter).filter(Boolean).length;
    },
  },
  methods: {
    ...mapActions(["getAuthsByShifts"]),

    convertType: shiftAuths.convertType,

    async fetchAuths() {
      const payload = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
        filter: this.filter,
      };

      this.loading = true;

      let skipFinally = false;

      try {
        const data = await this.getAuthsByShifts(payload);

        this.tableData = data.authorizations_by_shift;
        this.pagination.total = data.total_count;
        this.total_auths = data.total_auths;
        this.total_shifts_with_auths = data.total_shifts_with_auths;
      } catch (error) {
        if (error === "canceled") {
          skipFinally = true;
          this.loading = true;
          return;
        }
        this.showNotification("error", "Error", error);
      } finally {
        if (!skipFinally) this.loading = false;
      }
    },

    handleTableChange(pagination) {
      const isPaginationChanged =
        pagination.current !== this.pagination.current ||
        pagination.pageSize !== this.pagination.pageSize;

      if (!isPaginationChanged) {
        return;
      }

      this.pagination = { ...pagination };

      this.fetchAuths();
    },

    formatDateString(dateString) {
      return moment(dateString).format(this.workDateFormat);
    },

    showShiftAuthorizations(authRecord) {
      if (this.$refs.shiftAuthListModal) {
        this.$refs.shiftAuthListModal.clearData();
      }
      this.currentShiftRecord = authRecord;
      this.currentShiftAuthsList = authRecord.authorizations;
      this.authorizationsListVisible = true;
    },

    handleSelectedAuthListChange(authList) {
      this.selectedCurrentShiftAuthsList = authList;
    },

    acceptSelectedAuthList() {
      this.apiSetShiftAuthStatusForMultipleAuths(
        this.selectedCurrentShiftAuthsList,
        AuthStatus.ACCEPTED,
        this.resolveComment
      )
        .then(({ body }) => {
          if (body.error_code && body.error_code !== "0") {
            this.showNotification(
              "error",
              "Error",
              "Authorization statuses were not set due to an error. Try again"
            );
            this.closeCurrentShiftAuthsModalWithListClean();
            return;
          }

          this.setShiftAuthStatusesLocally(AuthStatus.ACCEPTED);
          this.updateTotalAuths();

          this.showNotification(
            "success",
            "Success",
            "Authorization statuses were set successfully"
          );
        })
        .finally(() => this.closeCurrentShiftAuthsModalWithListClean());
    },

    denySelectedAuthList() {
      this.apiSetShiftAuthStatusForMultipleAuths(
        this.selectedCurrentShiftAuthsList,
        AuthStatus.DECLINED,
        this.resolveComment,
        this.params
      )
        .then(({ body }) => {
          if (body.error_code && body.error_code !== "0") {
            this.showNotification(
              "error",
              "Error",
              "Authorization statuses were not set due to an error. Try again"
            );
            this.cleanShiftAuthsEditModalData();
            return;
          }

          this.setShiftAuthStatusesLocally(AuthStatus.DECLINED);

          this.showNotification(
            "success",
            "Success",
            "Authorization statuses were set successfully"
          );
        })
        .finally(() => this.cleanShiftAuthsEditModalData());
    },

    setShiftAuthStatusesLocally(status) {
      this.currentShiftRecord.authorizations.forEach((auth) => {
        if (this.selectedCurrentShiftAuthsList.includes(auth.id)) {
          auth.status = status;
          auth.reviewed_by = {
            first_name: this.user.first_name,
            last_name: this.user.last_name,
          };
          auth.review_date = moment();
        }
      });

      if (
        !this.currentShiftRecord.authorizations.some(
          (auth) => auth.status == AuthStatus.INITIAL
        )
      ) {
        this.tableData = this.tableData.filter(
          (item) => item.shift.id !== this.currentShiftRecord.shift.id
        );
      }
    },

    updateTotalAuths() {
      this.total_auths -= this.selectedCurrentShiftAuthsList.length;
      if (
        !this.currentShiftRecord.authorizations.some(
          (auth) =>
            auth.status == AuthStatus.INITIAL ||
            auth.status == AuthStatus.DECLINED
        )
      ) {
        this.total_shifts_with_auths -= 1;
      }
    },

    initAcceptSelectedAuthList() {
      this.closeCurrentShiftAuthsModal();
      this.acceptCommentModalOpen();
    },
    initDenySelectedAuthList() {
      this.closeCurrentShiftAuthsModal();
      this.denyReasonSelectorOpen();
    },

    acceptCommentModalOpen() {
      this.acceptCommentVisible = true;
    },
    denyReasonSelectorOpen() {
      const fullSelectedAuths = this.currentShiftAuthsList.filter((auth) =>
        this.selectedCurrentShiftAuthsList.includes(auth.id)
      );

      this.denyReasonVisible = true;
      this.$nextTick(() => {
        this.$refs.shiftAuthDenyForm.setAuths(fullSelectedAuths);
      });
    },
    setResolveComment(reason) {
      this.resolveComment = reason;
    },
    setDenyParams(params) {
      this.params = params;
    },
    confirmDeny() {
      this.denyReasonVisible = false;
      if (
        this.resolveComment &&
        this.selectedCurrentShiftAuthsList &&
        this.selectedCurrentShiftAuthsList.length > 0
      ) {
        this.denySelectedAuthList();
      } else {
        notification["warning"]({
          message: "Error",
          description: "Please fill deny reason and try again",
        });
      }
    },
    confirmAccept() {
      this.acceptCommentVisible = false;

      if (
        this.selectedCurrentShiftAuthsList &&
        this.selectedCurrentShiftAuthsList.length > 0
      ) {
        this.acceptSelectedAuthList();
      }
    },
    closeCurrentShiftAuthsModal() {
      this.authorizationsListVisible = false;
    },
    cleanShiftAuthsEditModalData() {
      this.currentShiftAuthsList = [];
      this.currentShiftRecord = undefined;
      this.selectedCurrentShiftAuthsList = [];
      this.resolveComment = "";
      this.params = {
        readyForPayment: false,
        withWriteUp: false,
        suspend: 0,
        terminated: false,
      };
    },
    closeCurrentShiftAuthsModalWithListClean() {
      this.closeCurrentShiftAuthsModal();
      this.cleanShiftAuthsEditModalData();
    },

    handleAuthListCancel() {
      this.closeCurrentShiftAuthsModalWithListClean();
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    openWriteupHistory(associateNumber) {
      this.editingAssociateNumber = associateNumber;
      this.showHistoryWriteup = true;
    },

    closeWriteupHistory() {
      this.editingAssociateNumber = undefined;
      this.showHistoryWriteup = false;
    },

    getAuthStatusTagColor(auth) {
      if (auth.status == AuthStatus.ACCEPTED) {
        return "rgb(55, 138, 90)";
      } else if (auth.status == AuthStatus.DECLINED) {
        return "red";
      }

      return "";
    },

    openShift(shiftId) {
      this.editingAssociateShiftId = shiftId;
      this.visibleShiftModal = true;
    },

    closeAssociateShiftModalWithChanges() {
      this.closeShiftModal();
      this.fetchAuths();
    },

    handleShiftExists(overlappingShifts) {
      this.visibleAssociateShiftModal = false;

      this.visibleShiftAlreadyExistsModal = true;
      this.overlappingShifts = overlappingShifts;
    },

    endEditAssociateProfile() {
      this.editingAssociateNumber = undefined;
      this.associateProfileModalVisible = false;
    },

    handleEditedAssociateProfile() {
      this.fetchAuths();
    },

    startEditAssociateProfile(associateNumber) {
      this.editingAssociateNumber = associateNumber;
      this.associateProfileModalVisible = true;
    },

    openSmsSendModal(associate) {
      this.associateProfileToSendSms = associate;
    },
    closeSmsModal() {
      this.associateProfileToSendSms = undefined;
    },

    openFilters() {
      this.filtersModalVisible = true;
    },

    resetFilters() {
      this.$router.replace({ query: {} }).catch(() => {});
    },

    getAuthProviders(record) {
      return record.authorizations
        .filter((auth) => auth.provider !== null)
        .map(
          (auth) => `${auth.provider.first_name} ${auth.provider.last_name}`
        );
    },
    getConfirmationStatus(record) {
      return record.authorizations.find((auth) => auth.confirmation != null);
    },
    convertConfirmationStatus(confirmation) {
      return Util.capitalize(confirmation.status);
    },
    getConfirmationStatusColor(confirmation) {
      switch (confirmation.status) {
        case "confirmed":
          return statusTagColors.GREEN;
        case "declined":
          return statusTagColors.RED;
        default:
          return statusTagColors.GRAY;
      }
    },

    async getDeclineReasons() {
      if (!this.currentShiftRecord) {
        return null;
      }

      const associate = this.currentShiftRecord.associate;
      const shift = this.currentShiftRecord.shift;

      const estimatedLunchTimes = await this.apiGetEstimatedLunches(
        associate.associate_number,
        shift.scheduled_start,
        shift.scheduled_end,
        shift.lunch_waived
      ).then((response) => response.data);

      const date = moment(shift.scheduled_start).format(this.dateFormat);

      const scheduledStartMoment = moment(shift.scheduled_start);
      const scheduledEndMoment = moment(shift.scheduled_end);
      const scheduledStart = scheduledStartMoment.format(this.timeFormat);
      const scheduledEnd = scheduledEndMoment.format(this.timeFormat);

      const signedStartMoment = moment(shift.signed_start);
      const signedEndMoment = moment(shift.signed_end);
      const signedStart = signedStartMoment.format(this.timeFormat);
      const signedEnd = signedEndMoment.format(this.timeFormat);

      const lunchEndMoment = moment(shift.lunch_end);
      const estimatedLunchStartMoment = moment(
        estimatedLunchTimes.estimated_lunch_start
      );
      const estimatedLunchEndMoment = moment(
        estimatedLunchTimes.estimated_lunch_end
      );

      const secondLunchEndMoment = moment(shift.second_lunch_end);
      const estimatedSecondLunchStartMoment =
        estimatedLunchTimes.estimated_second_lunch_start
          ? moment(estimatedLunchTimes.estimated_second_lunch_start)
          : undefined;
      const estimatedSecondLunchEndMoment =
        estimatedLunchTimes.estimated_second_lunch_end
          ? moment(estimatedLunchTimes.estimated_second_lunch_end)
          : undefined;

      const startDifference = Util.formatMinutesToHoursAndMinutesDuration(
        Math.abs(scheduledStartMoment.diff(signedStartMoment, "minutes"))
      );
      const endDifference = Util.formatMinutesToHoursAndMinutesDuration(
        Math.abs(scheduledEndMoment.diff(signedEndMoment, "minutes"))
      );
      const lunchEndDifference = Util.formatMinutesToHoursAndMinutesDuration(
        Math.abs(lunchEndMoment.diff(estimatedLunchEndMoment, "minutes"))
      );
      const secondLunchEndDifference =
        Util.formatMinutesToHoursAndMinutesDuration(
          Math.abs(
            secondLunchEndMoment.diff(estimatedSecondLunchEndMoment, "minutes")
          )
        );

      const firstLunchDuration =
        estimatedLunchStartMoment && estimatedLunchEndMoment
          ? Util.formatMinutesToHoursAndMinutesDuration(
              estimatedLunchEndMoment.diff(estimatedLunchStartMoment, "minutes")
            )
          : undefined;
      const secondLunchDuration =
        estimatedSecondLunchStartMoment && estimatedSecondLunchEndMoment
          ? Util.formatMinutesToHoursAndMinutesDuration(
              estimatedSecondLunchEndMoment.diff(
                estimatedSecondLunchStartMoment,
                "minutes"
              )
            )
          : undefined;
      const scheduledFirstLunchPeriod =
        estimatedLunchStartMoment && estimatedLunchEndMoment
          ? estimatedLunchStartMoment.format(this.timeFormat) +
            " - " +
            estimatedLunchEndMoment.format(this.timeFormat)
          : undefined;
      const scheduledSecondLunchPeriod =
        estimatedSecondLunchStartMoment && estimatedSecondLunchEndMoment
          ? estimatedSecondLunchStartMoment.format(this.timeFormat) +
            " - " +
            estimatedSecondLunchEndMoment.format(this.timeFormat)
          : undefined;

      const signedFirstLunchEnd = lunchEndMoment.format(this.timeFormat);
      const signedSecondLunchEnd = secondLunchEndMoment.format(this.timeFormat);

      return {
        no_lunch_authorization: `On ${date}, you had scheduled ${
          firstLunchDuration ?? ""
        } unpaid meal break ${
          scheduledFirstLunchPeriod ?? ""
        }. You do not have authorization to not take the required meal break. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.`,

        no_second_lunch_authorization: `On ${date}, you had two scheduled ${
          secondLunchDuration ?? ""
        } unpaid meal breaks ${
          scheduledSecondLunchPeriod ?? ""
        }. You do not have authorization to not take the required two meal breaks. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.`,

        late_end_authorization: `On ${date}, your scheduled end was ${scheduledEnd}, but your signed end is ${signedEnd}. It's ${endDifference} later! You shouldn't sign shifts with a late end unless you have authorization. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.`,

        early_start_authorization: `On ${date}, your scheduled start was ${scheduledStart}, but your signed start is ${signedStart}. It's ${startDifference} earlier! You shouldn't sign shifts with early start unless you have authorization. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.`,

        early_lunch_end_authorization: `On ${date}, you had scheduled ${
          firstLunchDuration ?? ""
        } unpaid meal break ${
          scheduledFirstLunchPeriod ?? ""
        }. Your signed meal break end is ${signedFirstLunchEnd}. It's ${lunchEndDifference} earlier! You do not have authorization to take a meal break shorter than ${
          firstLunchDuration ?? "30m"
        }. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.`,

        early_second_lunch_end_authorization: `On ${date}, you had scheduled ${
          secondLunchDuration ?? ""
        } unpaid second meal break ${
          scheduledSecondLunchPeriod ?? ""
        }. Your signed second meal break end is ${signedSecondLunchEnd}. It's ${secondLunchEndDifference} earlier! You do not have authorization to take a second meal break shorter than ${
          secondLunchDuration ?? "30m"
        }. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.`,

        no_actual_time_authorization:
          "Shifts without tracked time must be verified by an administrator. Adding shifts without tracked worked time violates company policies and may lead to disciplinary action.",

        overtime_authorization:
          "You do not have authorization to work overtime. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.",

        edit_shift_authorization:
          "You do not have authorization to edit shifts unless approved. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.",

        shift_info:
          "Your shift information should be accurate and reflect actual work hours. Incorrect shift data may lead to disciplinary actions.",

        outside_work_area_authorization:
          "You do not have authorization to work and track activities outside of the assigned work area. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.",

        employee_issue:
          "Your actions during shifts are being monitored for compliance. Violations may result in removal of shifts from your schedule and / or disciplinary actions.",

        absence_on_shift_authorization:
          "You do not have authorization to skip your scheduled shifts. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.",

        late_start_authorization: `On ${date}, your scheduled start was ${scheduledStart}, but your signed start is ${signedStart}. It's ${startDifference} later! You shouldn't sign shifts with late start unless you have authorization. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.`,

        early_end_authorization: `On ${date}, your scheduled end was ${scheduledEnd}, but your signed end is ${signedEnd}. It's ${endDifference} earlier! You shouldn't sign shifts with an early end unless you have authorization. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.`,

        first_lunch_period_authorization:
          "You must take the authorized lunch period as scheduled. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.",

        signed_actual_time_discrepancy:
          "Mismatch between your signed and tracked shift times violates company policy. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.",

        no_rest_break_authorization:
          "Not taking scheduled rest breaks violates company policy. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.",

        manually_shift_authorization:
          "You must not submit manually added shifts unless they are confirmed to have actually been worked. Manually adding shifts that did not occur violates company policies and may lead to disciplinary action.",

        associate_not_recognized_authorization:
          "Only you should use your Associate to track the shift. Using your Associate by someone else violates company policy and may result in the removal of all your shifts from the schedule and/or disciplinary actions.",

        uncompleted_required_tasks_authorization:
          "Perform all critical tasks during your shift. If your duties do not allow you to perform them, seek permission immediately. Observing these rules ensures safe and efficient work.",

        attachments_missing_authorization:
          "Not attaching required files violates company policy. Continued non-compliance may result in removal of all their shifts from the schedule and / or disciplinary actions.",

        unscheduled_shift:
          "The submission lacks the required documentation or information, which violates company policy. Please ensure all necessary forms and details are provided for future submissions. Continued non-compliance may result in the removal of all future shifts from the schedule and/or disciplinary actions.",
      };
    },

    openCloneAssociate() {
      this.cloneAssociateVisible = true;
      this.cloningAssociateNumber = this.editingAssociateNumber;
    },

    endCloneAssociate() {
      this.cloneAssociateVisible = false;
      this.cloningAssociateNumber = undefined;
      this.editingAssociateNumber = newAssociateNumber;
    },

    startTour() {
      this.$tours["authsTour"].start();
    },
  },

  mounted() {
    this.calculateTableHeight();
    window.addEventListener("resize", this.calculateTableHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.calculateTableHeight);
  },
};
</script>

<style scoped>
.table_predefined_filters {
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  flex-shrink: 1;
  align-items: center;
  justify-content: end;
}

.auth-tag {
  margin-top: 3px;
}

.orange-highlight {
  background-color: #ffb546;
}
</style>
