import Vue from "vue";
import Vuex from "vuex";
import InitialSettingsModalContent from "@/components/initial-settings-modal-content.vue";
import loadStatePlugin from "./plugins/loadState";
import state from "./state";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
import roles from "./modules/roles";
import payroll from "./modules/payroll";
import profile from "./modules/profile";
import shifts from "./modules/shifts";
import payment from "./modules/payment";
import report from "./modules/report";
import paycode from "./modules/paycode";
import document from "./modules/document";
import search from "./modules/search";
import legalEntity from "./modules/legal-entity";
import department from "./modules/department";

Vue.use(Vuex);
Vue.component("InitialSettingsModalContent", InitialSettingsModalContent);

export const applicationStateModule = {
  state,
  mutations,
  actions,
  getters,
};

export default new Vuex.Store({
  modules: {
    applicationState: applicationStateModule,
    payroll,
    roles,
    profile,
    shifts,
    payment,
    report,
    paycode,
    document,
    search,
    legalEntity,
    department,
  },
  plugins: [loadStatePlugin],
});
