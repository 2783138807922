<template>
  <a-modal
    title="New Associate"
    width="1000px"
    :visible="visible"
    :footer="null"
    @cancel="closeModal"
    :destroy-on-close="true"
  >
    <a-form layout="inline" :form="form" @submit.prevent="handleSubmit">
      <div class="content-view">
        <div class="content-view-block">
          <h3>Personal Information</h3>
          <p>
            <b>First Name <span class="required-field">*</span></b>
            <a-form-item required>
              <a-input
                v-decorator="[
                  'firstName',
                  {
                    rules: [
                      {
                        required: true,
                        message: firstNameSuggestionMsg,
                        whitespace: true,
                      },
                      { pattern: namePattern, message: nameErrorMsg },
                    ],
                  },
                ]"
                placeholder="Enter first name"
              />
            </a-form-item>
          </p>
          <p>
            <b>Last Name <span class="required-field">*</span></b>
            <a-form-item required>
              <a-input
                v-decorator="[
                  'lastName',
                  {
                    rules: [
                      {
                        required: true,
                        message: lastNameSuggestionMsg,
                        whitespace: true,
                      },
                      { pattern: namePattern, message: nameErrorMsg },
                    ],
                  },
                ]"
                placeholder="Enter last name"
              />
            </a-form-item>
          </p>
          <p>
            <b>Email <span class="required-field">*</span></b>
            <a-form-item required>
              <a-input
                v-decorator="[
                  'email',
                  {
                    rules: [
                      { required: true, message: emailSuggestionMsg },
                      { type: 'email', message: emailErrorMsg },
                    ],
                  },
                ]"
                placeholder="Enter email"
              />
            </a-form-item>
          </p>
          <p>
            <b>Phone <span class="required-field">*</span></b>
            <a-form-item required>
              <a-input
                v-decorator="[
                  'phone',
                  {
                    rules: [
                      { required: true, message: phoneSuggestionMsg },
                      { pattern: /^\+\d{10,15}$/, message: phoneErrorMsg },
                    ],
                  },
                ]"
                placeholder="Enter phone"
              />
            </a-form-item>
          </p>
          <h3>Company Information</h3>
          <p>
            <b class="associate-number-label"
              >Associate Number <span class="required-field">*</span></b
            >
            <a-form-item required>
              <a-input
                v-decorator="[
                  'associateNumber',
                  {
                    rules: [
                      { required: true, message: associateNumberSuggestionMsg },
                    ],
                  },
                ]"
                placeholder="Enter associate number"
                @input="handleAssociateNumberChange"
              />
            </a-form-item>
          </p>

          <div class="view-button-group">
            <a-form-item>
              <a-button
                type="primary"
                html-type="submit"
                :loading="loadingForm"
                :disabled="enabledCreateButton"
                >Create
              </a-button>
            </a-form-item>
          </div>
        </div>
        <div class="content-view-block">
          <p>
            <b>Schedule Type</b>
            <a-form-item required>
              <a-select
                v-decorator="[
                  'scheduleType',
                  {
                    rules: [
                      {
                        required: false,
                        message: 'Please select a schedule type!',
                      },
                    ],
                    initialValue: undefined,
                  },
                ]"
                placeholder="Select ST"
              >
                <a-select-option
                  v-for="type in scheduleTypes"
                  :key="type.name"
                  :value="type.name"
                  >{{ type.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </p>

          <h3>Payment Information</h3>
          <p>
            <b>Payment Type</b>
            <a-form-item>
              <a-select
                v-decorator="[
                  'paymentType',
                  { rules: [{ required: false }], initialValue: undefined },
                ]"
                placeholder="Select PT"
                @change="handlePaymentTypeChange"
              >
                <a-select-option
                  v-for="type in paymentTypes"
                  :key="type.id"
                  :value="type.id"
                  >{{ type.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </p>
          <p>
            <b>Payment Status</b>
            <a-form-item>
              <a-select
                v-decorator="[
                  'paymentStatus',
                  { rules: [{ required: false }], initialValue: undefined },
                ]"
                placeholder="Select Payment Status"
              >
                <a-select-option
                  v-for="status in paymentStatuses"
                  :key="status.id"
                  :value="status.id"
                  >{{ status.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </p>
          <p v-if="isEmployeePaymentTypeSelected && isFullTimePaymentStatus">
            <b>Payment Method</b>
            <a-form-item>
              <a-select
                v-decorator="[
                  'paymentMethod',
                  { rules: [{ required: false }], initialValue: undefined },
                ]"
                placeholder="Select PM"
              >
                <a-select-option
                  v-for="method in paymentMethods"
                  :key="method.id"
                  :value="method.id"
                  >{{ method.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </p>
          <p v-if="isEmployeePaymentTypeSelected">
            <b>Rate, $</b>
            <a-form-item>
              <a-input-number
                v-decorator="[
                  'rate',
                  { rules: [{ required: false }], initialValue: undefined },
                ]"
                placeholder="Enter rate"
                :min="0"
              />
            </a-form-item>
          </p>
          <p v-if="isContractorPaymentTypeSelected">
            <b>Hourly Rate, $</b>
            <a-form-item>
              <a-input-number
                v-decorator="[
                  'rate',
                  { rules: [{ required: false }], initialValue: undefined },
                ]"
                placeholder="Enter rate"
                :min="0"
              />
            </a-form-item>
          </p>
        </div>
      </div>
    </a-form>
  </a-modal>
</template>

<script>
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Select,
  Switch,
  Tooltip,
} from "ant-design-vue";
import ConstData from "@/helpers/const-data";
import api from "@/api";
import Util from "@/util";
import moment from "moment-timezone";

const ScheduleType = Object.freeze({
  FIXED: "fixed",
  SELF_SCHEDULE: "self_schedule",
  FREE: "free",
});

const PaymentType = Object.freeze({
  EMPLOYEE: 3,
  CONTRACTOR: 6,
  VOLUNTEER: 5,
});

const PaymentStatus = Object.freeze({
  FULL_TIME: "full_time",
  PART_TIME: "part_time",
  ON_CALL: "on_call",
  TERMINATED: "terminated",
});

const PaymentMethod = Object.freeze({
  HOURLY: 0,
  SALARY: 1,
});

export default {
  components: {
    "a-modal": Modal,
    "a-switch": Switch,
    "a-card": Card,
    "a-form": Form,
    "a-form-item": Form.Item,
    "a-input": Input,
    "a-input-password": Input.Password,
    "a-input-number": InputNumber,
    "a-date-picker": DatePicker,
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-button": Button,
    "a-checkbox": Checkbox,
    "a-tooltip": Tooltip,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [api],
  data() {
    return {
      form: undefined,
      rate: undefined,
      scheduleTypes: [
        { name: "fixed", label: "Strict" },
        { name: "self_schedule", label: "Flexible" },
        { name: "free", label: "Free" },
      ],
      paymentTypes: [
        { id: PaymentType.EMPLOYEE, name: "Employee" },
        { id: PaymentType.CONTRACTOR, name: "Contractor" },
        { id: PaymentType.VOLUNTEER, name: "Volunteer" },
      ],
      paymentStatuses: [
        { id: PaymentStatus.FULL_TIME, label: "Full Time" },
        { id: PaymentStatus.PART_TIME, label: "Part Time" },
        { id: PaymentStatus.ON_CALL, label: "On Call" },
        { id: PaymentStatus.TERMINATED, label: "Terminated" },
      ],
      paymentMethods: [
        { id: PaymentMethod.HOURLY, label: "Hourly" },
        { id: PaymentMethod.SALARY, label: "Salary" },
      ],

      isEmployeePaymentTypeSelected: true,
      isContractorPaymentTypeSelected: false,

      paymentTypeDefaultValue: PaymentType.EMPLOYEE,
      paymentStatusDefaultValue: PaymentStatus.FULL_TIME,
      paymentMethodDefaultValue: PaymentMethod.HOURLY,
      scheduleTypeDefaultValue: ScheduleType.SELF_SCHEDULE,
      roleDefaultValue: undefined,

      namePattern: /^[A-Z][a-zA-Z\s-]{1,24}$/,
      associateNumberPattern: /^\d{6}$/,

      firstNameSuggestionMsg: "Please enter your first name",
      lastNameSuggestionMsg: "Please enter your last name",
      emailSuggestionMsg: "Please enter your email",
      phoneSuggestionMsg: "Please enter your phone number",
      passwordSuggestionMsg: "Please enter password",
      associateNumberSuggestionMsg:
        "Please enter associate number of new associate",

      nameErrorMsg:
        "The first name or last name must be between 2 to 25 characters long and must be capitalized. " +
        "Use letters (Latin alphabet), spaces, and hyphens only. ",
      emailErrorMsg: "Please enter correct email",
      phoneErrorMsg:
        "Please enter valid phone number. It should start with a + and contain 10 to 15 digits.",
      passwordErrorMsg: "Password must be longer than 6 characters.",
      associateNumberErrorMsg: "Associate number must be exactly 6 digits.",
      ssnErrorMsg: "Enter the SSN as 9 digits without hyphens",

      shiftActionsAutocompleteTooltip:
        "The associate will automatically start and end first and second meal break" +
        " and finish work to prevent policy violations in your company. Associate will self-sign the shift and have" +
        " the ability to change tracked work hours",
      requireShiftConfirmationTooltip:
        "Associate is required to get the shift confirmed before signing it",
      suspectedAssociateTooltip:
        "Activate if an associate misbehaves while on the job, doesn't check in on the app, " +
        "misses shifts, violates company policy or other rules",
      autoApprovePrTooltip:
        "Payment requests from this associate will be approved automatically",

      statesList: ConstData.usStates,
      countriesList: ConstData.countries,

      loadingForm: false,

      existingAssociateInfo: undefined,
      enabledCreateButton: false,

      loadingUsers: false,

      userInfo: {
        id: undefined,
        fullName: undefined,
        email: undefined,
      },

      userId: undefined,
      foundUsers: [],
    };
  },
  computed: {
    visualDateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
    serverDateFormat() {
      return this.$store.state.applicationState.serverDateFormat;
    },
    projectRoles() {
      return this.$store.state.applicationState.projectRoles;
    },
    agencies() {
      return this.$store.state.applicationState.currentProject.legal_entities;
    },

    fullName() {
      return this.userInfo.firstName + " " + this.userInfo.lastName;
    },
    email() {
      return this.userInfo.email;
    },
  },
  methods: {
    handleSearchUsersChange(value) {
      this.userId = value;
      this.$refs.associateSelect.blur();
    },

    handleAssociateNumberChange() {
      const associateNumberValue = this.form.getFieldValue("associateNumber");

      if (associateNumberValue) {
        this.apiIsAssociateNumberBusy(associateNumberValue).then((response) => {
          if (response.data.is_associate_number_busy) {
            this.form.setFields({
              associateNumber: {
                value: "",
                errors: [
                  new Error(
                    `Associate number ${associateNumberValue} is busy. Set another one`
                  ),
                ],
              },
            });
          }
        });
      }
    },

    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (err) {
          this.showIncorrectFormError();
          return;
        }

        this.loadingForm = true;

        let paymentType = Object.keys(PaymentType).find(
          (key) => PaymentType[key] === values.paymentType
        );

        if (paymentType) {
          paymentType = paymentType.toLowerCase();
        }

        const associateInformation = {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          phone: values.phone,
          associate_number: values.associateNumber,
          payment_type: paymentType,
          pay_status: values.paymentStatus,
          pay_method: values.paymentMethod
            ? Object.keys(PaymentMethod)
                .find((key) => PaymentMethod[key] === values.paymentMethod)
                .toLowerCase()
            : "hourly",
          rate: values.rate ? Util.convertMoneyToDb(values.rate) : 0,
        };

        this.apiCreateAssociate(associateInformation)
          .then((response) => {
            const data = response.data;

            if (data.error_code && data.error_code !== "0") {
              notification.warning({
                message: "Associate Create Error",
                description: data.msg,
              });

              return;
            }

            notification.success({
              message: "Associate Create Success",
              description: "The Associate was created",
            });

            this.closeModalWithCreatedAssociate(
              data.associate.associate_number
            );
          })
          .catch(() => {
            notification.warning({
              message: "Associate Create Error",
              description: "Please try again later",
            });
          })
          .finally(() => (this.loadingForm = false));
      });
    },

    handlePaymentTypeChange(value) {
      switch (value) {
        case PaymentType.EMPLOYEE:
          this.isEmployeePaymentTypeSelected = true;
          this.isContractorPaymentTypeSelected = false;
          break;
        case PaymentType.CONTRACTOR:
          this.isEmployeePaymentTypeSelected = false;
          this.isContractorPaymentTypeSelected = true;
          break;
        default:
          this.isEmployeePaymentTypeSelected = false;
          this.isContractorPaymentTypeSelected = false;
      }
    },

    isFullTimePaymentStatus() {
      return (
        this.form.getFieldValue("paymentStatus") === undefined ||
        this.form.getFieldValue("paymentStatus") === PaymentStatus.FULL_TIME
      );
    },

    showIncorrectFormError(msg) {
      notification.warning({
        message: "Incorrect form",
        description:
          msg ??
          "Some fields are not filled in or are filled in incorrectly. Please check the fields",
      });
    },

    closeModalWithCreatedAssociate(associateNumber) {
      this.$emit("close", associateNumber);
    },
    closeModal() {
      this.$emit("update:visible", false);
    },
  },

  mounted() {
    this.form = this.$form.createForm(this, { name: "create_associate_form" });
  },
};
</script>

<style scoped>
.content-view-block {
  min-width: 400px;
}

.create-user-with-associate-controls {
  padding: 10px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.associate-number-label {
  word-wrap: normal;
}
</style>

<style lang="css" scoped src="../assets/css/form-styles.css" />
