<template>
  <div>
    <div class="grid" v-for="item in roles" :key="item.key">
      <div class="col">
        <p class="text-lg font-bold m-0">{{ item.title }}</p>
        <p class="role-property-description">
          {{ item.description }}
        </p>
      </div>
      <div class="col-fixed">
        <a-switch
          :disabled="isDisabled"
          :checked="hasRole(item.key)"
          @change="handleSwitchChange(item)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { PERMISSIONS } from "@/modules/roles/constants";
import { Switch } from "ant-design-vue";

export default {
  components: {
    "a-switch": Switch,
  },
  props: {
    permissions: {
      type: Array,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      roles: this.getRoles(),
    };
  },
  methods: {
    getRoles() {
      return Object.entries(PERMISSIONS).map(([key, value]) => ({
        key,
        ...value,
      }));
    },

    hasRole(key) {
      return this.permissions.includes(key);
    },

    handleSwitchChange(item) {
      const updatedPermissions = this.hasRole(item.key)
        ? this.permissions.filter((roleItem) => roleItem !== item.key)
        : [...this.permissions, item.key];

      this.$emit("update:permissions", updatedPermissions);
    },
  },
};
</script>
