<template>
  <a-modal
    title="Filters"
    width="600px"
    :visible="visible"
    @cancel="cancel"
    :destroy-on-close="true"
  >
    <div class="grid">
      <b class="col-4">Associate</b>
      <search-associates
        class="col-8"
        :is-associate-id="true"
        :multiply="true"
        :value.sync="filters.associate_ids"
      />

      <b class="col-4">Date From</b>
      <a-date-picker
        class="col-8"
        :disabled-date="disabledStartDate"
        format="MM/DD/YYYY"
        placeholder="Start Date"
        v-model="dateFromProxy"
        valueFormat="YYYY-MM-DD"
      />

      <b class="col-4">Date To</b>
      <a-date-picker
        class="col-8"
        :disabled-date="disabledEndDate"
        format="MM/DD/YYYY"
        placeholder="End Date"
        v-model="dateToProxy"
        valueFormat="YYYY-MM-DD"
      />

      <b class="col-4">Shift Statuses</b>
      <a-select
        class="col-8"
        mode="multiple"
        v-model="filters.statuses"
        :allow-clear="true"
        :filter-option="false"
        placeholder="Status"
      >
        <a-select-option
          v-for="status in shiftStatuses"
          :key="status.key"
          :value="status.key"
        >
          {{ getShiftStatus(status.key).title }}
        </a-select-option>
      </a-select>

      <b class="col-4">Payment Statuses</b>
      <a-select
        class="col-8"
        mode="multiple"
        v-model="filters.payment_statuses"
        :allow-clear="true"
        :filter-option="false"
        placeholder="Status"
      >
        <a-select-option
          v-for="status in paymentStatuses"
          :key="status.key"
          :value="status.key"
        >
          {{ getShiftPaymentRequestStatus(status.key).title }}
        </a-select-option>
      </a-select>

      <b class="col-4">Payroll Auths</b>
      <a-select
        class="col-8"
        mode="multiple"
        v-model="payrollSelected"
        :allow-clear="true"
        :filter-option="false"
        placeholder="Type"
      >
        <a-select-option
          v-for="type in payrollAuths"
          :key="type.key"
          :value="type.key"
        >
          {{ getAuthType(type.key).title }}
        </a-select-option>
      </a-select>

      <b class="col-4">Disciplinary Auths</b>
      <a-select
        class="col-8"
        mode="multiple"
        v-model="disciplinarySelected"
        :allow-clear="true"
        :filter-option="false"
        placeholder="Type"
      >
        <a-select-option
          v-for="type in disciplinaryAuths"
          :key="type.key"
          :value="type.key"
        >
          {{ getAuthType(type.key).title }}
        </a-select-option>
      </a-select>

      <b class="col-4">Job Classification</b>
      <a-select
        class="col-8"
        v-model="roleProxy"
        :dropdown-match-select-width="false"
        :allow-clear="true"
        placeholder="Job Classification"
      >
        <a-select-option v-for="role in roles" :key="role.id" :value="role.id">
          {{ role.name }}
        </a-select-option>
      </a-select>

      <b class="col-4">Shift Type</b>
      <a-select
        class="col-8"
        v-model="filters.shift_type"
        :allow-clear="true"
        :dropdown-match-select-width="false"
        placeholder="Shift Type"
      >
        <a-select-option value="worked"> Worked </a-select-option>
        <a-select-option value="pay_code"> Pay Code </a-select-option>
      </a-select>

      <template v-if="filters.shift_type === 'worked'"
        ><b class="col-4">With attached pay codes</b>
        <a-checkbox
          class="col-8"
          :checked="isAttachedChecked"
          @change="handleAttachedChange"
        />
      </template>

      <b class="col-4">Payment Type</b>
      <a-select
        class="col-8"
        v-model="filters.payment_type"
        :allow-clear="true"
        :dropdown-match-select-width="false"
        placeholder="Payment Type"
      >
        <a-select-option
          v-for="type in paymentTypes"
          :key="type.id"
          :value="type.name"
        >
          {{ type.name }}
        </a-select-option>
      </a-select>

      <b class="col-4">Pay Codes</b>
      <a-select
        class="col-8"
        v-model="payCodeProxy"
        :allow-clear="true"
        :dropdown-match-select-width="false"
        placeholder="Pay Codes"
      >
        <a-select-option
          v-for="payCode in payCodes"
          :key="payCode.id"
          :value="payCode.id"
        >
          {{ payCode.name }}
        </a-select-option>
      </a-select>

      <div class="flex gap-2 flex-wrap mt-2">
        <a-button
          @click="toggleFilter('manually_created')"
          :type="filters.manually_created ? 'primary' : 'default'"
        >
          Manually Created
        </a-button>
        <a-button
          @click="toggleFilter('with_files')"
          :type="filters.with_files ? 'primary' : 'default'"
        >
          With Attachments
        </a-button>
        <a-button
          @click="toggleFilter('adjusted')"
          :type="filters.adjusted ? 'primary' : 'default'"
        >
          Adjusted
        </a-button>
      </div>
    </div>
    <template slot="footer">
      <div class="flex justify-content-end">
        <a-button type="primary" @click="apply"> Apply </a-button>
        <a-button type="danger" :disabled="isEmptyFilters" @click="reset">
          Reset
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import {
  Modal,
  Button,
  Select,
  Checkbox,
  Switch,
  DatePicker,
} from "ant-design-vue";
import moment from "moment-timezone";
import SearchAssociates from "@/components/search-associates.vue";
import {
  SHIFT_STATUS,
  SHIFT_PAYMENT_REQUEST_STATUS,
  AUTH_STATUSES,
} from "@/store/modules/shifts/constants";
import { createNamespacedHelpers } from "vuex";

const { mapGetters: shiftGetters } = createNamespacedHelpers("shifts");
const { mapState: payCodeState, mapActions: payCodeActions } =
  createNamespacedHelpers("paycode");

const filtersBaseState = Object.freeze({
  associate_ids: undefined,
  statuses: [
    "deleted",
    "missed",
    "not_signed",
    "cancelled",
    "completed",
    "started",
    "scheduled",
  ],
  payment_statuses: [
    "unpaid",
    "auth_issues",
    "not_paid",
    "draft",
    "paid",
    "in_progress",
    "partially_paid",
    "cancelled",
  ],
  auth_types: [],
  pay_code: undefined,
  role: undefined,
  payment_type: undefined,
  end_date: undefined,
  start_date: undefined,
  shift_type: undefined,
  attached_pay_code: undefined,
  manually_created: undefined,
  with_pay_codes: undefined,
  with_files: undefined,
  adjusted: undefined,
});

export default {
  components: {
    "a-modal": Modal,
    "a-button": Button,
    "a-select": Select,
    "a-checkbox": Checkbox,
    "a-select-option": Select.Option,
    "a-switch": Switch,
    "a-date-picker": DatePicker,
    "search-associates": SearchAssociates,
  },
  data() {
    return {
      filters: { ...filtersBaseState },

      shiftStatuses: Object.entries(SHIFT_STATUS).map(([key, value]) => ({
        key,
        ...value,
      })),

      disciplinaryAuths: Object.entries(AUTH_STATUSES)
        .map(([key, value]) => ({
          key,
          ...value,
        }))
        .filter((item) => item.type === "disciplinary"),

      payrollAuths: Object.entries(AUTH_STATUSES)
        .map(([key, value]) => ({
          key,
          ...value,
        }))
        .filter((item) => item.type === "payroll"),

      paymentStatuses: Object.entries(SHIFT_PAYMENT_REQUEST_STATUS).map(
        ([key, value]) => ({
          key,
          ...value,
        })
      ),
      roles: this.$store.state.applicationState.projectRoles,
      paymentTypes: this.$store.state.applicationState.paymentTypes,
    };
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    currentFilters: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...shiftGetters([
      "getShiftStatus",
      "getShiftPaymentRequestStatus",
      "getAuthType",
    ]),
    ...payCodeState({
      payCodes: (state) => state.payCodes,
    }),

    isEmptyFilters() {
      return JSON.stringify(filtersBaseState) === JSON.stringify(this.filters);
    },

    dateFromProxy: {
      get() {
        return this.filters.start_date ?? null;
      },
      set(value) {
        this.filters.start_date = value === null ? undefined : value;
      },
    },

    dateToProxy: {
      get() {
        return this.filters.end_date ?? null;
      },
      set(value) {
        this.filters.end_date = value === null ? undefined : value;
      },
    },

    roleProxy: {
      get() {
        return this.filters.role ? Number(this.filters.role) : undefined;
      },
      set(value) {
        this.filters.role = value;
      },
    },

    payCodeProxy: {
      get() {
        return this.filters.pay_code
          ? Number(this.filters.pay_code)
          : undefined;
      },
      set(value) {
        this.filters.pay_code = value;
        this.filters.with_pay_codes = value ? true : undefined;
      },
    },

    payrollSelected: {
      get() {
        return (this.filters.auth_types ?? []).filter((key) =>
          this.payrollAuths.some((auth) => auth.key === key)
        );
      },
      set(values) {
        this.filters.auth_types = [...values, ...this.disciplinarySelected];
      },
    },

    disciplinarySelected: {
      get() {
        return (this.filters.auth_types ?? []).filter((key) =>
          this.disciplinaryAuths.some((auth) => auth.key === key)
        );
      },
      set(values) {
        this.filters.auth_types = [...values, ...this.payrollSelected];
      },
    },

    isAttachedChecked() {
      return this.filters.attached_pay_code === "true";
    },
  },
  watch: {
    currentFilters: {
      handler(newValue) {
        this.filters = { ...newValue };
      },
      immediate: true,
      deep: true,
    },
    visible: {
      handler() {
        this.filters = { ...this.currentFilters };
      },
    },
    "filters.shift_type": {
      handler(newVal) {
        if (newVal === "worked") {
          this.filters.attached_pay_code = false;
        } else {
          this.filters.attached_pay_code = undefined;
        }
      },
    },
  },
  methods: {
    ...payCodeActions(["fetchPayCodes"]),

    reset() {
      if (this.isEmptyFilters) {
        this.cancel();
      } else {
        this.filters = { ...filtersBaseState };
        this.$router.replace({ query: {} }).catch(() => {});
      }
    },

    cancel() {
      this.$emit("update:visible", false);
    },

    apply() {
      this.cancel();

      const { statuses, payment_statuses, ...rest } = this.filters;

      let query = { ...rest };

      if (
        JSON.stringify(statuses) !== JSON.stringify(filtersBaseState.statuses)
      ) {
        query.statuses = statuses;
      }

      if (
        JSON.stringify(payment_statuses) !==
        JSON.stringify(filtersBaseState.payment_statuses)
      ) {
        query.payment_statuses = payment_statuses;
      }

      this.$router.replace({ query }).catch(() => {});
    },

    handleAttachedChange(e) {
      this.filters.attached_pay_code = e.target.checked ? "true" : "false";
    },

    disabledStartDate(current) {
      const endValue = this.filters.end_date;
      if (!endValue) {
        return false;
      }

      return current && current.valueOf() > moment(endValue).valueOf();
    },

    disabledEndDate(current) {
      const startValue = this.filters.start_date;
      if (!startValue) {
        return false;
      }

      return current && current.valueOf() <= moment(startValue).valueOf();
    },

    toggleFilter(type) {
      this.filters[type] = this.filters[type] ? undefined : true;
    },

    async fetchPayCodesInfo() {
      try {
        this.fetchPayCodes({});
      } catch {
        this.showNotification("error", "Error", error);
      }
    },
  },
  mounted() {
    this.fetchPayCodesInfo();

    const defaultValues = {
      end_date: moment().format("YYYY-MM-DD"),
    };

    if (!this.$route.query.end_date) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          end_date: defaultValues.end_date,
        },
      });
    }
  },
};
</script>

<style scoped>
.ant-calendar-picker {
  margin: 0;
}
</style>
