<template>
  <div>
    <div class="flex flex-column gap-3 align-items-center">
      <a-upload-dragger
        class="upload-dragger"
        :file-list="fileList"
        :before-upload="beforeUpload"
        :remove="handleRemove"
        :multiple="true"
      >
        <p class="ant-upload-drag-icon">
          <a-icon type="inbox" />
        </p>
        <p class="ant-upload-text">Click or drag file to this area to upload</p>
        <p class="ant-upload-hint">Support for upload of images</p>
      </a-upload-dragger>
      <a-button
        class="upload-button"
        type="primary"
        :disabled="fileList.length == 0 || uploading"
        @click="upload"
      >
        Upload
      </a-button>
    </div>
  </div>
</template>

<script>
import { Button, notification, Upload, Icon } from "ant-design-vue";
import api from "@/api";

export default {
  props: ["attachmentsFileIds"],
  components: {
    "a-upload-dragger": Upload.Dragger,
    "a-button": Button,
    "a-icon": Icon,
  },
  data() {
    return {
      fileList: [],
      uploading: false,
    };
  },
  mixins: [api],
  methods: {
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];

      return true;
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();

      newFileList.splice(index, 1);

      this.fileList = newFileList;
    },
    async upload() {
      try {
        this.uploading = true;
        const response = await this.apiUploadFiles(this.fileList);

        if (
          !response.data ||
          !response.data.ids ||
          response.data.ids.length === 0
        ) {
          throw new Error("Error occurred while uploading files.");
        }

        const ids = response.data.ids;

        this.$emit(
          "attachments-saved",
          ids.map((item) => ({ id: item }))
        );
      } catch (error) {
        this.showNotification(
          "error",
          "Error",
          "An error occurred. Please try again."
        );
      } finally {
        this.uploading = false;
        this.fileList = [];
      }
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },
  },
  mounted() {
    if (this.attachmentsFileIds && this.attachmentsFileIds.length > 0) {
      this.fileList = this.attachmentsFileIds;
    }
  },
};
</script>

<style scoped>
.upload-dragger {
  width: 90%;
}
.upload-button {
  width: 100px;
}
</style>
