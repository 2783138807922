<template>
  <a-select
    show-search
    :mode="mode"
    v-model="associatesProxy"
    :autoClearSearchValue="false"
    :allow-clear="true"
    :loading="loadingAssociated"
    :filter-option="false"
    :disabled="disabled"
    :placeholder="loadingAssociated ? 'Loading...' : 'Associate'"
    @search="searchAssociates"
    @focus="focusAssociates"
    @blur="blurAssociates"
  >
    <a-select-option
      v-for="user in users"
      :key="getUserKey(user)"
      :value="getUserValue(user)"
    >
      <a-tooltip placement="left">
        <template #title>
          <span>{{ getTagText(user) }}</span>
        </template>
        <a-badge :color="getTagColor(user)" />
      </a-tooltip>
      {{ `[${user.employee_id}] ${user.first_name} ${user.last_name}` }}
    </a-select-option>
    <template slot="dropdownRender" slot-scope="menu">
      <div style="padding: 5px 12px" @mousedown.prevent>
        <a-checkbox :checked="includeDisabled" @change="handleCheck">
          Include Disabled
        </a-checkbox>
      </div>
      <a-divider style="margin: 0" />
      <a-spin tip="Loading..." :spinning="loadingAssociated">
        <v-nodes :vnodes="menu" />
      </a-spin>
    </template>
  </a-select>
</template>

<script>
import { debounce } from "@/helpers/debounce";
import {
  Badge,
  Checkbox,
  Divider,
  notification,
  Select,
  Spin,
  Tooltip,
} from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";

const {
  mapState: searchState,
  mapActions: searchActions,
  mapMutations: searchMutation,
} = createNamespacedHelpers("search");

export default {
  components: {
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-badge": Badge,
    "a-checkbox": Checkbox,
    "a-tooltip": Tooltip,
    "a-divider": Divider,
    "a-spin": Spin,
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    multiply: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String | Array,
      default: undefined,
    },
    isAssociateId: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingAssociated: false,
      searchQuery: "",
      includeDisabled: false,

      defaultAssociates: [],
    };
  },
  watch: {
    searchQuery: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal === "" && this.defaultAssociates.length) {
            this.setUsers(this.defaultAssociates);
          }

          if (!this.defaultAssociates.length || newVal !== "")
            this.fetchAssociates();
        }
      },
      immediate: true,
    },
    includeDisabled: {
      handler() {
        this.fetchAssociates();
      },
    },
  },
  computed: {
    ...searchState({
      users: (state) => state.users,
    }),

    mode() {
      return this.multiply ? "multiple" : "default";
    },

    associatesProxy: {
      get() {
        if (this.loadingAssociated) {
          return undefined;
        }

        if (this.isAssociateId) {
          if (Array.isArray(this.value)) {
            return this.value.map(Number);
          }
          return this.value ? Number(this.value) : undefined;
        }
        return this.value || undefined;
      },
      set(value) {
        if (value === undefined) {
          this.searchQuery = "";
        }

        this.$emit("update:value", value);
      },
    },
  },
  methods: {
    ...searchActions(["getUsers"]),
    ...searchMutation(["setUsers"]),

    getUserKey(user) {
      return this.isAssociateId ? user.associate_id : user.employee_id;
    },

    getUserValue(user) {
      return this.isAssociateId ? user.associate_id : user.employee_id;
    },

    handleCheck(e) {
      this.includeDisabled = e.target.checked;
    },

    getTagColor(user) {
      if (!user.is_active) {
        return "#b4b4b4";
      } else if (user.termination) {
        return "#ff4646";
      } else {
        return "#34db30";
      }
    },
    getTagText(user) {
      if (!user.is_active) {
        return "Disabled";
      } else if (user.termination) {
        return "Terminated";
      } else {
        return "Active";
      }
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    searchAssociates: debounce(function (value) {
      this.searchQuery = value;
    }, 1000),

    focusAssociates() {
      this.searchQuery = "";
    },

    blurAssociates() {
      this.searchQuery = "";
    },

    // async calls

    async fetchAssociates() {
      this.loadingAssociated = true;
      try {
        await this.getUsers({
          query: this.searchQuery,
          disabled: this.includeDisabled,
        });

        if (!this.defaultAssociates.length) {
          this.defaultAssociates = this.users;
        }
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingAssociated = false;
      }
    },
  },
};
</script>

<style>
.ant-tooltip {
  z-index: 11000 !important;
}
</style>
