import { render, staticRenderFns } from "./shift-attachments-upload.vue?vue&type=template&id=412e2e1a&scoped=true&"
import script from "./shift-attachments-upload.vue?vue&type=script&lang=js&"
export * from "./shift-attachments-upload.vue?vue&type=script&lang=js&"
import style0 from "./shift-attachments-upload.vue?vue&type=style&index=0&id=412e2e1a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "412e2e1a",
  null
  
)

export default component.exports