import apiClient from "@/services/api";

export default {
  async getDepartments({ commit, rootState }, { page, size }) {
    try {
      const { data } = await apiClient.get(`/api/departments`, {
        params: {
          page,
          size,
          project_id: rootState.applicationState.currentProject.id,
        },
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg}. Please try again later`);
      }

      commit("setDepartments", data.departments);
    } catch (error) {
      throw error.message;
    }
  },

  async createDepartment({ rootState }, { department }) {
    try {
      const { data } = await apiClient.post(`/api/departments`, {
        department: {
          ...department,
          project_id: rootState.applicationState.currentProject.id,
        },
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },

  async updateDepartment({ rootState }, { department }) {
    try {
      const { data } = await apiClient.patch(
        `/api/departments/${department.id}`,
        {
          department: {
            ...department,
            project_id: rootState.applicationState.currentProject.id,
          },
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },

  async deleteDepartment({}, { id }) {
    try {
      const { data } = await apiClient.delete(`/api/departments/${id}`);

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },
};
