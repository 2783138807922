import shiftsHelper from "@/helpers/shifts";
import util from "@/util";
import moment from "moment-timezone";

export default {
  mixins: [shiftsHelper],
  data() {
    return {
      pagination: {
        pageSizeOptions: ["10", "50", "100"],
        pageSize: 50,
        current: 1,
        total: 0,
        showSizeChanger: true,
      },

      tableScrollHeight: 0,

      columns: [
        {
          title: "Shift ID",
          dataIndex: "shift.id",
          key: "shiftId",
          align: "center",
          sorter: true,
        },
        {
          title: "Date",
          key: "date",
          scopedSlots: { customRender: "date" },
          align: "center",
          sorter: true,
        },
        {
          title: "Associate",
          key: "associate",
          scopedSlots: { customRender: "associate" },
          align: "center",
          sorter: true,
        },
        {
          title: "Providers",
          key: "providers",
          scopedSlots: { customRender: "providers" },
          align: "center",
        },
        {
          title: "Confirmation",
          key: "confirmation",
          scopedSlots: { customRender: "confirmation" },
          align: "center",
        },
        {
          title: "Authorizations",
          key: "authorizations",
          scopedSlots: { customRender: "authorizations" },
          align: "center",
        },
        {
          title: "History",
          key: "history",
          scopedSlots: { customRender: "history" },
        },
        {
          title: "Actions",
          key: "actions",
          scopedSlots: { customRender: "actions" },
          align: "center",
        },
      ],

      nestedColumns: [
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
        },
        {
          title: "Date",
          key: "date",
          width: 300,
          customRender: (_, record) => {
            const { start, end } = record;
            const formatted =
              start && end ? this.formatDateRange(start, end) : " - ";

            return formatted;
          },
        },
        {
          title: "Start",
          key: "start",
          dataIndex: "start",
          customRender: (_, record) => {
            const colorClass =
              record.type === "Signed" && record.signed_start_diff_info
                ? record.signed_start_diff_info.color
                : "";

            const formatted = record.start
              ? this.formatDate(record.start)
              : " - ";

            const mainDiv = <div class={`${colorClass}`}>{formatted}</div>;

            if (record.signed_start_diff_info) {
              const tooltipTitle = `Signed start time differs ${record.signed_start_diff_info.difference} minutes from tracked time.`;
              return (
                <a-tooltip>
                  <template slot="title">{tooltipTitle}</template>
                  {mainDiv}
                </a-tooltip>
              );
            }

            return mainDiv;
          },
        },
        {
          title: "End",
          key: "end",
          dataIndex: "end",
          customRender: (_, record) => {
            const colorClass =
              record.type === "Signed" && record.signed_end_diff_info
                ? record.signed_end_diff_info.color
                : "";

            const formatted = record.end ? this.formatDate(record.end) : " - ";

            const mainDiv = <div class={`${colorClass}`}>{formatted}</div>;

            if (record.signed_end_diff_info) {
              const tooltipTitle = `Signed end time differs ${record.signed_end_diff_info.difference} minutes from tracked time`;
              return (
                <a-tooltip>
                  <template slot="title">{tooltipTitle}</template>
                  {mainDiv}
                </a-tooltip>
              );
            }

            return mainDiv;
          },
        },
        {
          title: "Dur",
          key: "duration",
          customRender: (_, record) => {
            const { start, end } = record;

            if (!start) {
              return " - ";
            }

            const formatted = util.getFormattedDuration(start, end);

            return formatted;
          },
        },
        {
          title: "Work Hrs",
          key: "work_hours",
          customRender: (_, record) => {
            const {
              start,
              end,
              first_lunch_start,
              first_lunch_end,
              second_lunch_start,
              second_lunch_end,
            } = record;

            const formatted =
              this.formatWorkHours(
                start ? moment.utc(start) : null,
                end ? moment.utc(end) : null,
                first_lunch_start ? moment.utc(first_lunch_start) : null,
                first_lunch_end ? moment.utc(first_lunch_end) : null,
                second_lunch_start ? moment.utc(second_lunch_start) : null,
                second_lunch_end ? moment.utc(second_lunch_end) : null
              ) ?? " - ";

            return formatted;
          },
        },
        {
          title: "Pay Hrs",
          key: "pay_hours",
          customRender: (_, record) => {
            const {
              scheduled_start,
              scheduled_end,
              start,
              end,
              first_lunch_start,
              first_lunch_end,
              second_lunch_start,
              second_lunch_end,
              type,
            } = record;

            if (type === "Scheduled") {
              return " - ";
            }

            const formatted =
              this.formatPayHours(
                scheduled_start ? moment.utc(scheduled_start) : null,
                scheduled_end ? moment.utc(scheduled_end) : null,
                start ? moment.utc(start) : null,
                end ? moment.utc(end) : null,
                first_lunch_start ? moment.utc(first_lunch_start) : null,
                first_lunch_end ? moment.utc(first_lunch_end) : null,
                second_lunch_start ? moment.utc(second_lunch_start) : null,
                second_lunch_end ? moment.utc(second_lunch_end) : null
              ) ?? " - ";

            return formatted;
          },
        },
        {
          title: "L1 Start",
          key: "first_lunch_start",
          customRender: (_, record) => {
            const { first_lunch_start } = record;

            const formatted = first_lunch_start
              ? this.formatDate(first_lunch_start)
              : " - ";

            return formatted;
          },
        },
        {
          title: "L1 End",
          key: "first_lunch_end",
          customRender: (_, record) => {
            const { first_lunch_end } = record;

            const formatted = first_lunch_end
              ? this.formatDate(first_lunch_end)
              : " - ";

            return formatted;
          },
        },
        {
          title: "L1 Dur",
          customRender: (_, record) => {
            const { first_lunch_end, first_lunch_start } = record;

            if (!first_lunch_start) {
              return " - ";
            }

            const formatted = util.getFormattedDuration(
              first_lunch_start,
              first_lunch_end
            );

            return formatted;
          },
        },
        {
          title: "L2 Start",
          key: "second_lunch_start",
          customRender: (_, record) => {
            const { second_lunch_start } = record;

            const formatted = second_lunch_start
              ? this.formatDate(second_lunch_start)
              : " - ";

            return formatted;
          },
        },
        {
          title: "L2 End",
          key: "second_lunch_end",
          customRender: (_, record) => {
            const { second_lunch_end } = record;

            const formatted = second_lunch_end
              ? this.formatDate(second_lunch_end)
              : " - ";

            return formatted;
          },
        },
        {
          title: "L2 Dur",
          customRender: (_, record) => {
            const { second_lunch_start, second_lunch_end } = record;

            if (!second_lunch_start) {
              return " - ";
            }

            const formatted = util.getFormattedDuration(
              second_lunch_start,
              second_lunch_end
            );

            return formatted;
          },
        },
      ],
    };
  },
  methods: {
    calculateTableHeight() {
      this.tableScrollHeight = window.innerHeight - 260;
    },

    getSignedDiff(startTime, signedTime) {
      const differenceInMinutes = Math.abs(
        moment(startTime).diff(moment(signedTime), "minutes")
      );

      return {
        difference: differenceInMinutes,
        color: differenceInMinutes > 30 ? "orange-highlight" : null,
      };
    },

    formatDate(date) {
      return moment(date).format(this.$store.state.applicationState.timeFormat);
    },

    expandedRowRender(record) {
      const data = [
        {
          key: "scheduled",
          type: "Scheduled",
          start: record.shift.scheduled_start,
          end: record.shift.scheduled_end,
          first_lunch_start: null,
          first_lunch_end: null,
          first_lunch_duration: null,
          second_lunch_start: null,
          second_lunch_end: null,
        },
        {
          key: "actual",
          type: "Actual",
          start: record.shift.actual_work_start,
          end: record.shift.actual_work_end,
          first_lunch_start: record.shift.actual_lunch_start,
          first_lunch_end: record.shift.actual_lunch_end,
          first_lunch_duration: record.shift.actual_lunch_duration,
          second_lunch_start: record.shift.actual_second_lunch_start,
          second_lunch_end: record.shift.actual_second_lunch_end,
        },
        {
          key: "signed",
          type: "Signed",
          start: record.shift.signed_start,
          end: record.shift.signed_end,
          scheduled_start: record.shift.scheduled_start,
          scheduled_end: record.shift.scheduled_end,
          first_lunch_start: record.shift.lunch_start,
          first_lunch_end: record.shift.lunch_end,
          first_lunch_duration: record.shift.lunch_duration,
          second_lunch_start: record.shift.second_lunch_start,
          second_lunch_end: record.shift.second_lunch_end,
          signed_start_diff_info: this.getSignedDiff(
            record.shift.signed_start,
            record.shift.actual_work_start
          ),
          signed_end_diff_info: this.getSignedDiff(
            record.shift.signed_end,
            record.shift.actual_work_end
          ),
        },
      ];

      return (
        <a-table
          size="small"
          columns={this.nestedColumns}
          dataSource={data}
          pagination={false}
        />
      );
    },
  },
};
