<template>
  <a-modal title="Filters" width="600px" v-model="visible" @cancel="apply">
    <div class="content-view">
      <div class="content-view-block w-full">
        <p>
          <b>Associate</b>
          <search-associates
            class="filters-select"
            :value.sync="filters.associate"
          />
        </p>
        <p>
          <b>Type</b>
          <a-select
            class="filters-select"
            v-model="filters.type"
            :allow-clear="true"
            placeholder="Type"
          >
            <a-select-option
              v-for="(status, index) in timeOffRequestTypes"
              :key="index"
              :value="status"
            >
              {{ convertTypeToText(status) }}
            </a-select-option>
          </a-select>
        </p>
        <p>
          <b>Status</b>
          <a-select
            class="filters-select"
            v-model="filters.status"
            :allow-clear="true"
            placeholder="Status"
          >
            <a-select-option
              v-for="(status, index) in timeOffRequestStatuses"
              :key="index"
              :value="status"
            >
              {{ convertStatusToText(status) }}
            </a-select-option>
          </a-select>
        </p>
      </div>
    </div>
    <template slot="footer">
      <div class="flex justify-content-end">
        <a-button type="primary" @click="apply"> Apply </a-button>
        <a-button type="danger" :disabled="disableResetButton" @click="reset">
          Reset
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { Modal, Button, Select } from "ant-design-vue";
import api from "@/api";
import { timeOffRequestStatusMap, timeOffRequestTypeMap } from "@/const";
import SearchAssociates from "@/components/search-associates.vue";

const filtersBaseState = Object.freeze({
  associate: undefined,
  type: undefined,
  status: undefined,
});

export default {
  components: {
    "a-modal": Modal,
    "a-button": Button,
    "a-select": Select,
    "a-select-option": Select.Option,
    "search-associates": SearchAssociates,
  },
  mixins: [api],
  data() {
    return {
      filters: { ...filtersBaseState },
    };
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    currentFilters: {
      type: Object,
      required: true,
    },
  },
  computed: {
    timeOffRequestTypes() {
      return Object.keys(timeOffRequestTypeMap);
    },
    timeOffRequestStatuses() {
      return Object.keys(timeOffRequestStatusMap);
    },
    roles() {
      return this.$store.state.applicationState.projectRoles;
    },
    disableResetButton() {
      return JSON.stringify(filtersBaseState) == JSON.stringify(this.filters);
    },
  },
  watch: {
    visible(newVal) {
      this.localVisible = newVal;
    },
    localVisible(newVal) {
      this.$emit("update:visible", newVal);
    },
  },
  methods: {
    reset() {
      this.filters = { ...filtersBaseState };
    },
    apply() {
      const filtersToApply = { ...this.filters };
      this.$emit("apply", filtersToApply);
    },

    convertTypeToText(timeOffType) {
      return timeOffRequestTypeMap[timeOffType] ?? timeOffType;
    },
    convertStatusToText(timeOffStatus) {
      return timeOffRequestStatusMap[timeOffStatus] ?? timeOffStatus;
    },
  },
  mounted() {
    this.filters = { ...this.currentFilters };
  },
};
</script>

<style scoped>
.daily-summary-filters {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.content-view-block {
  max-width: unset;
}
.filters-select {
  width: 250px;
}

.filters-select .ant-select-dropdown {
  width: 200px;
}

.filters-select .ant-select-dropdown-menu-item {
  white-space: normal;
  word-wrap: break-word;
}
</style>
