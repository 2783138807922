export default {
  getAvailableRoles(state, _, rootState) {
    const roles = rootState.applicationState.projectRoles;
    const departments = state.departments;

    return roles.filter(
      (role) =>
        !departments.some((department) =>
          department.roles.some((depRole) => depRole.id === role.id)
        )
    );
  },
};
