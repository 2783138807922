<template>
  <a-modal
    :visible="visible"
    title="Mark Paid Several"
    @cancel="cancelMarkPaid(false)"
    :destroy-on-close="true"
  >
    <div class="content-view">
      <div class="grid w-full">
        <b class="col-4">Associates</b>
        <search-associates
          class="col-8"
          :value.sync="associateNumbers"
          :multiply="true"
        />

        <b class="col-4">Job Classification</b>
        <a-select
          class="col-8"
          v-model="role"
          :dropdown-match-select-width="false"
          :allow-clear="true"
          placeholder="Job Classification"
          :disabled="associateNumbers.length > 0"
          @change="handleRoleChange"
        >
          <a-select-option
            v-for="role in roles"
            :key="role.id"
            :value="role.id"
          >
            {{ role.name }}
          </a-select-option>
        </a-select>

        <b class="col-4">Range</b>
        <a-range-picker
          class="col-8"
          :ranges="pickerRanges"
          separator="-"
          :format="dateFormat"
          v-model="dates"
          @change="onDateRangeChange"
        />
      </div>
    </div>
    <template slot="footer">
      <div class="flex justify-content-end">
        <a-button
          type="primary"
          :loading="loading"
          @click="markPaidForRange"
          :disabled="!isMarkPaidEnabled"
        >
          Mark Paid
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import {
  Modal,
  Button,
  Select,
  DatePicker,
  notification,
} from "ant-design-vue";
import api from "@/api";
import moment from "moment-timezone";
import store from "@/store";
import SearchAssociates from "@/components/search-associates.vue";
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers("shifts");

export default {
  components: {
    "a-modal": Modal,
    "a-button": Button,
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-range-picker": DatePicker.RangePicker,
    "search-associates": SearchAssociates,
  },
  mixins: [api],
  props: {
    visible: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      associateNumbers: [],
      dates: undefined,
      role: undefined,
      loading: false,

      dateFormat: store.state.applicationState.dateFormat,
      serverDateFormat: store.state.applicationState.serverDateFormat,
    };
  },
  watch: {
    visible: {
      handler(newVal) {
        if (!newVal) {
          this.associateNumbers = [];
          this.dates = undefined;
          this.role = undefined;
        }
      },
      immediate: true,
    },
  },
  computed: {
    roles() {
      return this.$store.state.applicationState.projectRoles;
    },

    pickerRanges() {
      return {
        Today: [moment(), moment()],
        "This Week (From Today)": [moment(), moment().endOf("week")],
        "Next Week": [
          moment().add(1, "week").startOf("week"),
          moment().add(1, "week").endOf("week"),
        ],
        "This Month (From Today)": [moment(), moment().endOf("month")],
        "Next Month": [
          moment().add(1, "month").startOf("month"),
          moment().add(1, "month").endOf("month"),
        ],
      };
    },

    isMarkPaidEnabled() {
      return (
        ((this.associateNumbers && this.associateNumbers.length > 0) ||
          this.role) &&
        this.dates
      );
    },

    dateFrom() {
      if (!this.dates || this.dates.length !== 2) {
        return undefined;
      }

      return this.dates[0].format(this.serverDateFormat);
    },

    dateTo() {
      if (!this.dates || this.dates.length !== 2) {
        return undefined;
      }

      return this.dates[1].format(this.serverDateFormat);
    },
  },
  methods: {
    ...mapActions(["markShiftsAsPaidOnRange"]),

    cancelMarkPaid(dataChanged) {
      this.$emit("cancel", dataChanged);
    },

    handleSearchAssociatesChange(value) {
      if (value.length > 0) {
        this.role = undefined;
      }
    },
    handleRoleChange(value) {
      if (value) {
        this.associateNumbers = [];
      }
    },

    onDateRangeChange(dates) {
      this.dates = dates;
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    async markPaidForRange() {
      this.loading = true;

      const payload = {
        date_from: this.dateFrom,
        date_to: this.dateTo,
        associate_numbers: this.associateNumbers,
        role: this.role,
      };

      try {
        await this.markShiftsAsPaidOnRange(payload);

        this.showNotification(
          "success",
          "Success",
          "Shifts are marked as paid"
        );

        this.cancelMarkPaid(true);
      } catch (error) {
        this.showNotification("error", "Error", error.message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.ant-calendar-picker {
  margin: 0;
}
</style>
