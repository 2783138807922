import apiClient from "@/services/api";

export default {
  async getDocumentByType(_, { type, associate_id }) {
    try {
      const { data } = await apiClient.post(`/api/document/project/type/get`, {
        type,
        associate_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      return data.document;
    } catch (error) {
      throw error.message;
    }
  },

  async downloadAssociateDocument(_, { associate_id, document_id }) {
    try {
      const { data } = await apiClient.post(
        "/api/document/associate/download",
        {
          associate_id,
          document_id,
        }
      );

      if ((data.error_code && data.error_code !== "0") || !data.file_url) {
        throw new Error("Please try again later");
      }

      return data.file_url;
    } catch (error) {
      throw error.message;
    }
  },

  async downloadAllAssociateDocuments(_, { associate_id }) {
    try {
      const { data } = await apiClient.post(
        "/api/document/associate/download/all",
        {
          associate_id,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      return data.file_url;
    } catch (error) {
      throw error.message;
    }
  },

  async sendAssociateDocument(_, { associate_id, document_id, email }) {
    try {
      const { data } = await apiClient.post(
        "/api/document/associate/send_email",
        {
          associate_id,
          document_id,
          email,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }
    } catch (error) {
      throw error.message;
    }
  },

  async sendAllAssociateDocument(_, { associate_id, email }) {
    try {
      const { data } = await apiClient.post(
        "/api/document/associate/send_email/all",
        {
          associate_id,
          email,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }
    } catch (error) {
      throw error.message;
    }
  },

  async getProjectDocuments({ state, rootState, dispatch }, { filters }) {
    try {
      if (!state.groups || state.groups.length === 0) {
        await dispatch("getDocumentsGroups");
      }

      const group = state.groups.find(
        (item) => item.id === Number(filters.group)
      );

      const { data } = await apiClient.post(`/api/document/project/get_all`, {
        project_id: rootState.applicationState.currentProject.id,
        ...filters,
        group,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      return data;
    } catch (error) {
      throw error.message;
    }
  },

  async getDocumentsGroups({ rootState, commit }) {
    try {
      const { data } = await apiClient.post(
        `/api/document/project/group/get_all`,
        {
          project_id: rootState.applicationState.currentProject.id,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setGroups", data.groups);
    } catch (error) {
      throw error.message;
    }
  },

  async getProjectDocument({ rootState, commit }, { document_id }) {
    try {
      const { data } = await apiClient.post(`/api/document/project/get`, {
        project_id: rootState.applicationState.currentProject.id,
        associate_id: rootState.applicationState.associate.id,
        document_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setDocument", data.document);
    } catch (error) {
      throw error.message;
    }
  },

  async createDocument({ rootState }, { document }) {
    try {
      const { data } = await apiClient.post(`/api/document/project/create`, {
        project_id: rootState.applicationState.currentProject.id,
        document,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }
    } catch (error) {
      throw error.message;
    }
  },

  async updateDocument({ rootState }, { document }) {
    try {
      const { data } = await apiClient.post(`/api/document/project/update`, {
        project_id: rootState.applicationState.currentProject.id,
        document,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }
    } catch (error) {
      throw error.message;
    }
  },

  async deleteDocument({ rootState }, { document_id }) {
    try {
      const { data } = await apiClient.post(`/api/document/project/delete`, {
        project_id: rootState.applicationState.currentProject.id,
        document_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }
    } catch (error) {
      throw error.message;
    }
  },
};
