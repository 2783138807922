import { render, staticRenderFns } from "./auth.vue?vue&type=template&id=c08a1af4&scoped=true&"
import script from "./auth.vue?vue&type=script&lang=js&"
export * from "./auth.vue?vue&type=script&lang=js&"
import style0 from "./auth.vue?vue&type=style&index=0&id=c08a1af4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c08a1af4",
  null
  
)

export default component.exports