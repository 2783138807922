<template>
  <a-modal
    title="Send To Email"
    :visible="visible"
    width="500px"
    @cancel="cancel"
    :destroy-on-close="true"
  >
    <a-input placeholder="Email" v-model="email" />

    <template slot="footer">
      <div class="flex justify-content-between">
        <a-button @click="cancel"> Cancel </a-button>
        <a-button type="primary" @click="submit" :loading="loading">
          Send To Email
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { Button, Input } from "ant-design-vue";

export default {
  components: {
    "a-input": Input,
    "a-button": Button,
  },
  watch: {
    visible: {
      handler() {
        this.email = undefined;
      },
      immediate: true,
    },
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    send: {
      type: Function,
    },
    close: {
      type: Function,
    },
  },
  data() {
    return {
      email: undefined,
    };
  },
  methods: {
    cancel() {
      this.$emit("close");
    },
    submit() {
      this.$emit("send", this.email);
    },
  },
};
</script>
