<template>
  <div>
    <send-modal
      :visible="visibleSendModal"
      :loading="loadingSend"
      @close="closeSendModal"
      @send="sendReport"
    />

    <h3>Exported Associate Profile</h3>
    <div class="flex flex-col gap-2">
      <a-button
        :loading="loadingSend"
        icon="mail"
        type="primary"
        @click="openSendModal"
      >
        Send To Email
      </a-button>
      <a-button
        :loading="loadingDownload"
        icon="upload"
        type="primary"
        @click="downloadReport"
      >
        Download
      </a-button>
    </div>
  </div>
</template>

<script>
import SendModal from "@/components/send-modal.vue";
import { Button, notification } from "ant-design-vue";
import { createNamespacedHelpers } from 'vuex';

const { mapActions: profileActions, mapState: profileState } =
  createNamespacedHelpers("profile");

export default {
  components: {
    "a-button": Button,
    "send-modal": SendModal,
  },
  data() {
    return {
      visibleSendModal: false,
      loadingSend: false,
      loadingDownload: false,
    };
  },
  computed: {
    ...profileState({
      associate: (state) => state.associate,
    }),
  },
  methods: {
    ...profileActions(["sendExportedAssociate", "downloadExportedAssociate"]),

    closeSendModal() {
      this.visibleSendModal = false;
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    openSendModal() {
      this.visibleSendModal = true;
    },

    async sendReport(email) {
      this.closeSendModal();

      this.loadingSend = true;

      const payload = {
        associate_id: this.associate.id,
        email,
      };

      try {
        await this.sendExportedAssociate(payload);

        this.showNotification(
          "success",
          "Success",
          "Exported Associate Profile successfully sent to email"
        );
      } catch (error) {
        this.showNotification("error", "Error", error.message || error);
      } finally {
        this.loadingSend = false;
      }
    },

    async downloadReport() {
      this.loadingDownload = true;

      console.log(this.associate)

      try {
        const url = await this.downloadExportedAssociate({
          associate_id: this.associate.id,
        });

        window.open(url, "_blank");
      } catch (error) {
        this.showNotification("error", "Error", error.message || error);
      } finally {
        this.loadingDownload = false;
      }
    },
  },
};
</script>
