<template>
  <div>
    <h3>Activity Info</h3>
    <div v-if="loadingAssociate" class="loading-spin">
      <a-spin />
    </div>
    <template v-else
      ><div class="grid" style="width: 400px">
        <b class="col-6">Status</b>
        <div class="col-6 text-right">
          <a-tag class="m-0" :color="getStatus.color">{{
            getStatus.title
          }}</a-tag>
        </div>

        <template v-if="context">
          <template v-if="context.associate">
            <b class="col-6">Profile Creator</b>
            <div class="col-6 text-right">
              <grouped-associate-info
                :first-name="context.associate.first_name"
                :last-name="context.associate.last_name"
                :associate-number="context.associate.associate_number"
              /></div
          ></template>

          <b class="col-6">Profile Created At</b>
          <div class="col-6 text-right">
            {{ formatDateString(context.created_at) }}
          </div>

          <b class="col-6">Creation Source</b>
          <div class="col-6 text-right">
            {{ context.origin }}
          </div>
        </template>

        <b class="col-6">Hire Date</b>
        <div class="col-6 text-right">
          {{
            associate?.hire_date
              ? formatDateString(associate?.hire_date)
              : " - "
          }}
        </div>

        <b class="col-6">Seniority Date</b>
        <div class="col-6 text-right">
          {{
            associate?.seniority_date
              ? formatDateString(associate?.seniority_date)
              : " - "
          }}
        </div>

        <template v-if="isFutureTermination"
          ><b class="col-6">Future Termination</b>
          <div class="col-6 text-right">
            {{
              associate?.resigned_date
                ? formatDateString(associate?.resigned_date)
                : " - "
            }}
          </div>
        </template>
      </div>
      <div class="grid" style="width: 400px">
        <template v-if="!isTerminated">
          <b class="col-6">Author</b>
          <div class="col-6 text-right">{{ terminationAuthorName }}</div>

          <b class="col-6">Reason</b>
          <div class="col-6 text-right">{{ associate.termination.reason }}</div>

          <b class="col-6">Terminated Date</b>
          <div class="col-6 text-right">
            {{ formatDateString(associate.termination.termination_date) }}
          </div>

          <b class="col-6">Submission Date</b>
          <div class="col-6 text-right">
            {{ formatDateString(associate.termination.created_at) }}
          </div>

          <div class="col-12 flex gap-2">
            <a-button icon="redo" @click="openActivityConfirm(true)"
              >Rehire</a-button
            ><a-button
              v-if="associate && associate.is_active"
              icon="redo"
              @click="openActivityConfirm(false)"
              >Disable</a-button
            >
          </div>
        </template>

        <template v-else>
          <div class="col-12 flex gap-2" v-if="!creatingTermination">
            <a-button @click="switchSetTermination" type="danger"
              >Create Termination</a-button
            >
          </div>

          <template v-else>
            <b class="col-6"
              >Terminated Date <span class="required-field">*</span></b
            >
            <div class="col-6 text-right">
              <a-date-picker
                v-model="newTermination.termination_date"
                placeholder="Enter termination date"
                class="w-full"
                :format="visualDateFormat"
              />
            </div>

            <b class="col-6">Reason <span class="required-field">*</span></b>
            <div class="col-6 text-right">
              <a-textarea
                v-model="newTermination.reason"
                :autoSize="true"
                placeholder="Enter termination reason"
              />
            </div>
            <div class="col-10">
              <p class="text-lg font-bold m-0">
                Terminate ALL Associate Profiles
              </p>
              <p class="m-0">
                Associate will be terminated and will completely lose access to
                the company. Do not use this option when closing a specific
                Associate Profile.
              </p>
            </div>

            <a-checkbox
              class="col-2 text-right"
              :checked="terminateAll"
              @change="handleCheckTerminateAll"
            />

            <div class="col-12 flex gap-2 justify-content-end">
              <a-button @click="cancelTermination">Cancel</a-button>
              <a-button
                type="danger"
                @click="openTerminationConfirm"
                :disabled="saveTerminationDisabled"
              >
                Terminate
              </a-button>
            </div>
          </template>
        </template>
      </div></template
    >
  </div>
</template>

<script>
import GroupedAssociateInfo from "@/components/grouped-associate-info.vue";
import util from "@/util";
import Util from "@/util";
import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  Modal,
  notification,
  Spin,
  Tag,
} from "ant-design-vue";
import moment from "moment-timezone";
import { createNamespacedHelpers } from "vuex";

const { mapActions: profileActions } = createNamespacedHelpers("profile");

const StatusTagColors = Object.freeze({
  GRAY: "#b4b4b4",
  GREEN: "#34db30",
  RED: "#ff4646",
});

export default {
  components: {
    "a-button": Button,
    "a-date-picker": DatePicker,
    "a-textarea": Input.TextArea,
    "a-tag": Tag,
    "a-checkbox": Checkbox,
    "a-spin": Spin,
    "grouped-associate-info": GroupedAssociateInfo,
  },
  data() {
    return {
      creatingTermination: false,

      newTermination: {
        reason: undefined,
        termination_date: undefined,
      },

      isSwitchChecked: false,

      associate: undefined,
      terminateAll: false,

      loadingAssociate: false,

      context: undefined,
    };
  },
  props: {
    associateId: {
      type: Number,
      default: null,
    },
  },
  watch: {
    associateId: {
      handler(newVal) {
        if (newVal) {
          this.fetchAssociate();
        }
      },
    },
  },
  computed: {
    isFutureTermination() {
      return (
        this.associate?.resigned_date &&
        moment()
          .utc()
          .startOf("day")
          .isSameOrBefore(this.associate?.resigned_date)
      );
    },
    isTerminated() {
      return !this.associate?.termination ?? false;
    },
    getStatus() {
      if (this.associate && this.associate.is_active) {
        return {
          title: "ACTIVE",
          color: StatusTagColors.GREEN,
        };
      } else {
        return {
          title: "DISABLED",
          color: StatusTagColors.RED,
        };
      }
    },
    terminationAuthorName() {
      if (this.isTerminated) {
        return "-";
      }

      const suspensionAuthor = this.associate.termination.author;
      return Util.combineAssociateName(
        suspensionAuthor.first_name,
        suspensionAuthor.last_name
      );
    },
    saveTerminationDisabled() {
      return (
        !this.newTermination.termination_date ||
        !this.newTermination.reason ||
        this.newTermination.reason.length === 0
      );
    },
    visualDateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
  },
  methods: {
    ...profileActions([
      "patchAssociateActivate",
      "patchAssociateDeactivate",
      "postAssociateTerminate",
      "postAssociateCancelTerminate",
      "getAssociateById",
      "getCreationContext",
    ]),

    formatDateString(dateString) {
      return moment(dateString).utc().format("MM/DD/YYYY");
    },
    showNotification(type, message, description) {
      notification[type]({
        message: message,
        description: description,
      });
    },
    switchSetTermination() {
      this.creatingTermination = !this.creatingTermination;
    },
    resetNewTermination() {
      this.newTermination = {
        termination_date: undefined,
        reason: undefined,
      };
      this.terminateAll = false;
    },
    cancelTermination() {
      this.resetNewTermination();
      this.switchSetTermination();
    },

    handleCheckTerminateAll(e) {
      this.terminateAll = e.target.checked;
    },

    openTerminationConfirm() {
      Modal.confirm({
        title: "Final Paycheck is Ready?",
        content: `Make sure we can issue the final paycheck to the associate within 24 hours of the termination date. If that's not possible, please suspend the associate's work and prepare the paycheck.`,
        onOk: async () => {
          await this.setTermination();
        },
        onCancel() {},
      });
    },

    openActivityConfirm(checked) {
      Modal.confirm({
        title: "Are you sure?",
        content: `You are about to ${
          checked ? "activate" : "disable"
        } an associate. ${
          checked
            ? "If you activate an associate, the termination will be canceled."
            : ""
        } If you are sure - click OK.`,
        onOk: async () => {
          await this.setActivity(checked);
        },
        onCancel() {},
      });
    },

    async setActivity(checked) {
      try {
        if (checked) {
          await this.patchAssociateActivate({
            associate_id: this.associateId,
          });
        } else {
          await this.patchAssociateDeactivate({
            associate_id: this.associateId,
          });
        }

        this.showNotification(
          "success",
          "Activation Set",
          `Associate has been successfully ${
            checked ? "activated" : "disabled"
          }`
        );

        this.fetchAssociate();
        this.$emit("reset");
      } catch (error) {
        this.showNotification("error", "Activation Set Error", error);
      }
    },

    async setTermination() {
      const payload = {
        user_id: this.associate.user_id,
        termination: {
          ...this.newTermination,
          termination_date: moment(this.newTermination.termination_date)
            .utc()
            .startOf("day")
            .toISOString(),
        },
        associate_id: this.terminateAll ? undefined : this.associateId,
      };

      const isFuture = moment(payload.termination.termination_date).isAfter(
        moment().utc().startOf("day")
      );

      try {
        await this.postAssociateTerminate(payload);

        this.showNotification(
          "success",
          "Termination Set",
          isFuture
            ? `Associate will be terminated at ${this.formatDateString(
                moment(payload.termination.termination_date).add(1, "day")
              )}`
            : "Associate is now terminated"
        );

        this.cancelTermination();
        this.$emit("reset");
      } catch (error) {
        this.showNotification("error", "Termination Set Error", error);
      }
    },

    async fetchAssociate() {
      this.loadingAssociate = true;

      const payload = {
        associate_id: this.associateId,
      };

      try {
        const [associateResponse, creationContextResponse] = await Promise.all([
          this.getAssociateById(payload),
          this.getCreationContext(payload),
        ]);

        const { associate } = associateResponse;

        this.associate = associate;

        const { origin, ...otherData } = creationContextResponse;

        this.context = { ...otherData, origin: util.capitalize(origin) };
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingAssociate = false;
      }
    },
  },

  mounted() {
    if (this.associateId) this.fetchAssociate();
  },
};
</script>

<style scoped>
.ant-calendar-picker {
  margin: 0;
  min-width: unset !important;
}
</style>
